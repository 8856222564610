import { HttpHeaders } from "@angular/common/http";

export class Constants{
    public static GATEWAY_URL='/api/';
    public static ORIGIN_URL='https://mfactorycloud.co.za';
    public static KEY='123456$#@$^@1ERF';
}

export const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json','Access-Control-Allow-Origin': `${Constants.ORIGIN_URL}`,
      'Access-Control-Allow-Credentials': 'true','Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Methods': 'POST','skip':'true'
    })
};

export const httpOptionsWithAuth = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json','Access-Control-Allow-Origin': `${Constants.ORIGIN_URL}`,
    'Access-Control-Allow-Credentials': 'true','Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Methods': 'POST,GET,PUT,DELETE'
  })
};


export const environment = {
  production: false,
  apiUrl: Constants.GATEWAY_URL+'messages',
  wsUrl: 'ws://localhost:8080/chat-service/ws',
};
