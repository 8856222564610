import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RegisterUser } from '../models/models';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    private dataArray: any[] = [];
    private dataArraySubject: BehaviorSubject<RegisterUser[]> = new BehaviorSubject<RegisterUser[]>([]);

    constructor() { }

    public getArray() {
        return this.dataArraySubject.asObservable();
    }

    public addData(user: RegisterUser) {
        const newUser: RegisterUser = {
            contactNumber: user.contactNumber,
            email: user.email,
            fullName: user.fullName,
            lastName: user.lastName,
            position: user.position,
            role: user.role
        };

        this.dataArray.push(newUser)

        this.dataArraySubject.next([...this.dataArray]);
    }

    public clearData() {
        this.dataArray = [];
        this.dataArraySubject.next([]);
    }
}