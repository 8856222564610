<div class="d-flex justify-content-center ">
    <div class="main ">
        <p class="text-center" style="background-color: #FFF">{{ticket.description}}</p>
        <div class="scroll" *ngIf="messageList | async as messages">
            <div *ngIf="messages.length===0" class="text-center mt-5">
                <img src="../../../assets/img/e843d1ba-91d2-44be-b1ac-af846172b3d5.png" width="100" height="100">
                <p>No Messages</p>
            </div>
            <div *ngFor="let msg of messageList | async">
                <div *ngIf="msg.sender === accountId.accountId; else senderB"
                    class="d-flex align-items-center text-right justify-content-end ">
                    <div class="pr-2"> <span class="name"><!--{{msg.sender}}--></span>
                        <p class="msg">{{msg.content}}</p>
                    </div>
                    <div><img src="../../../assets/img/service-desk.png" width="30" class="img1 ml-2" /></div>
                </div>

                <ng-template #senderB>
                    <div class="d-flex align-items-center">
                        <div class="text-left pr-1"><img src="../../../assets/img/service-desk.png" width="30"
                                class="img1" /></div>
                        <div class="pr-2 pl-1"> <span class="name"><!--{{msg.sender}}--></span>
                            <p class="msg">{{msg.content}}</p>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <nav class="navbar bg-white navbar-expand-sm d-flex justify-content-between"> <input type="text"
                [(ngModel)]="newMessage" name="text" class="form-control" placeholder="Type a message...">
            <div class="icondiv d-flex justify-content-end align-content-center text-center ml-2 mr-2"><i
                    class="bi bi-send icon1" (click)="sendMessage()"></i> </div>
        </nav>
    </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>