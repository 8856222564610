<main id="main" class="main">
    <div class="pagetitle">
        <h1>Tickets</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">chat</li>
            </ol>
        </nav>
    </div>
    <section class="section">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card info-card admin-card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <h5 class="mt-3"><a routerLink="/tickets" title="Go Back" style="cursor: pointer; color: var(--primary-color);">
  <i class="bi bi-arrow-left-circle-fill"></i>
</a>&nbsp;&nbsp;Ticket ID: #{{TicketId.substring(0,4)}}</h5>
                                        <div class="row mt-3">
                                            <div class="col-md-6 mb-3">
                                                <h5>{{ticket.category}} &nbsp; <span [ngClass]="getPriorityColor(ticket.priority.toString())">{{ticket.priority}}</span></h5>
                                            </div>
                                            <div class="col-md-6 mb-3" *ngIf="role.role==='[ROLE_AGENT]'">
                                                <button type="button" class="btn btn-primary float-end"
                                                    data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                    Update Ticket
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="card text-black">

                                                    <app-chat [ticketId]="TicketId"></app-chat>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-4">
                                        <div class="card mt-5">
                                            <div class="card-header">
                                                <h5 class="">Ticket Information</h5>
                                            </div>
                                            <div class="card-body mt-2">
                                                <div class="text-black mb-2">
                                                    <ng-container *ngIf="TicketId">
                                                        <span class="name"><b> Ticket Ref:</b>
                                                            #{{TicketId.substring(0,4)}}</span><br>
                                                    </ng-container>

                                                    <ng-container *ngIf="role.role==='[ROLE_END_USER]' && assignedTo">
                                                        <span class="name"><b> Assigned To:
                                                            </b>{{assignedTo}}</span><br>
                                                    </ng-container>

                                                    <ng-container *ngIf="ticket.createdAt">
                                                        <span class="name"><b> Created:</b> {{ticket.createdAt |
                                                            date:"YY MMM YYYY"}} </span><br>
                                                    </ng-container>

                                                    <ng-container *ngIf="ticket.updateAt !== null">
                                                        <span class="name"><b> Last updated:</b> {{ticket.updateAt |
                                                            date:"YY MMM YYYY"}}</span><br>
                                                    </ng-container>

                                                    <ng-container *ngIf="ticket.status">
                                                       <b> Status:</b>&nbsp; <span [ngClass]="getStatusColor(ticket.status.toString())"> {{ticket.status}} </span><br>
                                                    </ng-container>

                                                    <ng-container *ngIf="ticket.priority">
                                                        <b> Priority:</b>&nbsp;<span  [ngClass]="getPriorityColor(ticket.priority.toString())"> {{ticket.priority}} </span>
                                                    </ng-container>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="modal fade" #myModal id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Update Ticket</h5>
                <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form [formGroup]="updateTicketForm" (ngSubmit)="updateTicket()">
                <div class="modal-body">
                    <div class="form-floating col-12 mb-2">
                        <select type="text" class="form-control" name="role" id="role" required formControlName="status"
                            [class.is-invalid]="f['status'].invalid && f['status'].touched"
                            (change)="onValueChange($event)">
                            <option value="Escalate">Escalate</option>
                            <option value="Closed">Close</option>
                        </select>
                        <label style="color: #6c757d;" for="category">Status</label>
                        <div class="text-center" *ngIf="f['status'].invalid && f['status'].touched">
                            <p style="color: crimson;" class="small mt-1" *ngIf="f['status'].errors?.['required']">
                                Status is
                                required!</p>
                        </div>
                    </div>

                    <div class="form-floating col-12 mb-2" *ngIf="!hide">
                        <select type="text" class="form-control" name="escalatedToAgentId" id="escalatedToAgentId"
                            required formControlName="escalatedToAgentId"
                            [class.is-invalid]="f['escalatedToAgentId'].invalid && f['escalatedToAgentId'].touched">
                            <option value="" disabled selected>Choose Agent</option>
                            <option *ngFor="let a of agents" value="{{a.accountId}}">{{a.fullName}}</option>
                        </select>
                        <label style="color: #6c757d;" for="escalatedToAgentId">Category</label>
                        <div class="text-center"
                            *ngIf="f['escalatedToAgentId'].invalid && f['escalatedToAgentId'].touched">
                            <p style="color: crimson;" class="small mt-1"
                                *ngIf="f['escalatedToAgentId'].errors?.['required']">
                                Agent is
                                required!</p>
                        </div>
                    </div>

                    <div class="form-floating col-12">
                        <textarea class="form-control" name="category" id="category" required
                            formControlName="updateMessage"
                            [class.is-invalid]="f['updateMessage'].invalid && f['updateMessage'].touched"
                            style="height: 100px;"></textarea>
                        <label style="color: #6c757d;" for="description">Resolution/Reason</label>
                        <div class="text-center" *ngIf="f['updateMessage'].invalid && f['updateMessage'].touched">
                            <p style="color: crimson;" class="small mt-1"
                                *ngIf="f['updateMessage'].errors?.['required']">
                                Message is
                                required!</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">Update
                        Ticket</button>
                </div>
            </form>
        </div>
    </div>
</div>