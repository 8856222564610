import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  showSuccess(message: string): void {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Success',
      text: message,
      showConfirmButton: false,
      timer: 2000
    });
  }

  showError(message: string): void {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Error',
      text: message,
      showConfirmButton: false,
      timer: 5000
    });
  }
}
