<main id="main" class="main">
    <div class="pagetitle">
        <h1>Register Administrator</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">register admin</li>
            </ol>
        </nav>
    </div>
    <section class="section">

        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card info-card admin-card">
                            <div class="card-body">
                                <div class="container mt-4">

                                    <div class="row gy-4">
                                        <div class="col-lg-12">
                                            <nav id="orders-table-tab"
                                                class="orders-table-tab app-nav-tabs nav shadow-sm flex-column flex-sm-row mb-4">
                                                <a class="flex-sm-fill text-sm-center nav-link active"
                                                    id="registered-tab" data-bs-toggle="tab" href="#registered"
                                                    role="tab" aria-controls="registered" aria-selected="true">
                                                    REGISTERED ADMINS</a>
                                                <a class="flex-sm-fill text-sm-center nav-link" id="experience-tab"
                                                    data-bs-toggle="tab" href="#register" role="tab"
                                                    aria-controls="register" aria-selected="false">REGISTER ADMIN</a>
                                            </nav>

                                            <div class="tab-content" id="orders-table-tab-content">
                                                <div class="tab-pane fade show active" id="registered" role="tabpanel"
                                                    aria-labelledby="registered">
                                                    <div class="app-card">
                                                        <div class="app-card-body p-2">
                                                            <h5 class="card-title">List of Users</h5>
                                                            <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Full Name</th>
                                                                        <th scope="col">Email</th>
                                                                        <th scope="col">Position</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let data of adminResponse">
                                                                        <td>{{data.fullName}}</td>
                                                                        <td>{{data.email}}</td>
                                                                        <td>{{data.position}}</td>
                                                                        
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade show" id="register" role="tabpanel"
                                                    aria-labelledby="register">
                                                    <div class="app-card">
                                                        <div class="app-card-body p-2">
                                                            <h5 class="text-start mb-3">Enter Administrator details</h5>
                                                            <form [formGroup]="registerAdminForm"
                                                                (ngSubmit)="registerAdmin()">
                                                                <div class="row gy-2 gx-md-3">
                                                                    <div class="form-floating col-md-6">
                                                                        <input type="text" name="name"
                                                                            class="form-control" id="name"
                                                                            formControlName="fullName"
                                                                            [class.is-invalid]="f['fullName'].invalid && f['fullName'].touched"
                                                                            required>
                                                                        <label style="color: #6c757d;" for="name">First
                                                                            Name</label>
                                                                        <div class="text-center"
                                                                            *ngIf="f['fullName'].invalid && f['fullName'].touched">
                                                                            <p style="color: crimson;"
                                                                                class="small mt-1"
                                                                                *ngIf="f['fullName'].errors?.['required']">
                                                                                First name is
                                                                                required!</p>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-floating col-md-6">
                                                                        <input type="text" name="lastname"
                                                                            class="form-control" id="lastname" required
                                                                            formControlName="lastName"
                                                                            [class.is-invalid]="f['lastName'].invalid && f['lastName'].touched">
                                                                        <label style="color: #6c757d;"
                                                                            for="lastname">Last Name</label>
                                                                        <div class="text-center"
                                                                            *ngIf="f['lastName'].invalid && f['lastName'].touched">
                                                                            <p style="color: crimson;"
                                                                                class="small mt-1"
                                                                                *ngIf="f['lastName'].errors?.['required']">
                                                                                Lastname is
                                                                                required!</p>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-floating col-md-6">
                                                                        <input type="text" name="contact"
                                                                            class="form-control" id="contact" required
                                                                            formControlName="contactNumber"
                                                                            [class.is-invalid]="f['contactNumber'].invalid && f['contactNumber'].touched">
                                                                        <label style="color: #6c757d;"
                                                                            for="contact">Contact
                                                                            Number</label>
                                                                        <div class="text-center"
                                                                            *ngIf="f['contactNumber'].invalid && f['contactNumber'].touched">
                                                                            <p style="color: crimson;"
                                                                                class="small mt-1"
                                                                                *ngIf="f['contactNumber'].errors?.['required']">
                                                                                Contact
                                                                                number is
                                                                                required!</p>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-floating col-md-6">
                                                                        <input type="email" name="email"
                                                                            class="form-control" id="email" required
                                                                            formControlName="email"
                                                                            [class.is-invalid]="f['email'].invalid && f['email'].touched">
                                                                        <label style="color: #6c757d;"
                                                                            for="email">Email</label>
                                                                        <div class="text-center"
                                                                            *ngIf="f['email'].invalid && f['email'].touched">
                                                                            <p style="color: crimson;"
                                                                                class="small mt-1"
                                                                                *ngIf="f['email'].errors?.['required']">
                                                                                Email is
                                                                                required!</p>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-floating col-12">
                                                                        <input type="text" class="form-control"
                                                                            name="position" id="position" required
                                                                            formControlName="position"
                                                                            [class.is-invalid]="f['position'].invalid && f['position'].touched">
                                                                        <label style="color: #6c757d;"
                                                                            for="position">Position</label>
                                                                        <div class="text-center"
                                                                            *ngIf="f['position'].invalid && f['position'].touched">
                                                                            <p style="color: crimson;"
                                                                                class="small mt-1"
                                                                                *ngIf="f['position'].errors?.['required']">
                                                                                Position is
                                                                                required!</p>
                                                                        </div>
                                                                    </div>

                                                                    <div class="text-end col-12"><button
                                                                            type="submit">Submit</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>