import { Component, OnInit } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{

  authToken!:any;
  role!:any;

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.role = jwt_decode(this.authToken);
  }
}