<div class="container mt-4">
    <div class="row gy-4">
        <div class="col-lg-12">
            <nav id="orders-table-tab" class="orders-table-tab app-nav-tabs nav shadow-sm flex-column flex-sm-row mb-4">
                <a class="flex-sm-fill text-sm-center nav-link active" id="profile-tab" data-bs-toggle="tab"
                    href="#profile" role="tab" aria-controls="profile" aria-selected="true">
                    Profile</a>
                <a class="flex-sm-fill text-sm-center nav-link" id="password-tab" data-bs-toggle="tab" href="#password"
                    role="tab" aria-controls="password" aria-selected="false">Change Password</a>
            </nav>
            <div class="tab-content" id="orders-table-tab-content">
                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile">
                    <div class="app-card">
                        <div class="app-card-body p-2">
                            <h5 class="card-title">Company Profile Information</h5>
                            <div class="container">
                                <div class="row">

                                    <div class="form-floating col-md-6 mb-3">
                                        <input type="text" name="name" class="form-control" id="name"
                                            formControlName="fullName" readonly>
                                        <label style="color: #6c757d;" for="name">Company Name</label>
                                    </div>

                                    <div class="form-floating col-md-6 mb-3">
                                        <input type="text" name="name" class="form-control" id="name" readonly>
                                        <label style="color: #6c757d;" for="name">Company Contact Number</label>
                                    </div>

                                    <div class="form-floating col-md-6 mb-3">
                                        <input type="text" name="name" class="form-control" id="name" readonly>
                                        <label style="color: #6c757d;" for="name">Company Email
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <h5 class="card-title">Address Information</h5>
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <h5 class="card-title">Physical Address</h5>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <h5 class="card-title">Postal Address</h5>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                        <div class="form-floating col-12 mb-4">
                                            <input type="text" class="form-control" name="password" id="password"
                                                required formControlName="password"
                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                            <label style="color: #6c757d;" for="password">New Password</label>
                                            <div class="text-center"
                                                *ngIf="f['password'].invalid && f['password'].touched">
                                                <p style="color: crimson;" class="small mt-1"
                                                    *ngIf="f['password'].errors?.['required']">
                                                    Password is
                                                    required!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-end col-12"><button type="submit">Update Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="tab-pane fade show" id="password" role="tabpanel" aria-labelledby="password">
                    <div class="app-card">
                        <div class="app-card-body p-2">
                            <h5 class="text-start mb-3">Change your password here</h5>

                            <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
                                <div class="form-floating col-12 mb-4">
                                    <input type="password" class="form-control" name="password" id="password" required
                                        formControlName="password"
                                        [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                    <label style="color: #6c757d;" for="password">New Password</label>
                                    <div class="text-center" *ngIf="f['password'].invalid && f['password'].touched">
                                        <p style="color: crimson;" class="small mt-1"
                                            *ngIf="f['password'].errors?.['required']">
                                            Password is
                                            required!</p>
                                        <p style="color: crimson;" class="small mt-1"
                                            *ngIf="f['password'].errors?.['pattern']">
                                            Password must contain minimum of 8 characters,at least one uppercase
                                            letter,at least one
                                            lowercase letter
                                            ,at least one number and at least one special character</p>
                                    </div>
                                </div>

                                <div class="form-floating col-12">
                                    <input type="password" class="form-control" name="repassword" id="repassword" required
                                        formControlName="repassword"
                                        [class.is-invalid]="f['repassword'].invalid && f['repassword'].touched">
                                    <label style="color: #6c757d;" for="password">Confirm Password</label>
                                    <div class="text-center" *ngIf="f['repassword'].invalid && f['repassword'].touched">
                                        <p style="color: crimson;" class="small mt-1"
                                            *ngIf="f['repassword'].errors?.['required']">
                                            Password is
                                            required!</p>
                                        <p style="color: crimson;" class="small mt-1"
                                            *ngIf="f['repassword'].errors?.['matchpassword']">Password don't match</p>
                                    </div>
                                </div>
                                <div class="text-end col-12"><button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>