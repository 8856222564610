import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';
import { Error } from 'src/app/util/error';
import { Agent, Data, TicketRespond, UpdateTicket } from 'src/app/util/models/models';
import { TicketService } from 'src/app/util/services/ticket.service';
import { getStatusColor, getPriorityColor } from 'src/app/util/status-prority-colors';
import { Successful } from 'src/app/util/successful';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-open-chat',
  templateUrl: './open-chat.component.html',
  styleUrls: ['./open-chat.component.css']
})
export class OpenChatComponent implements OnInit {

  @Input() TicketId: any;
  @ViewChild('myModal') myModal!: any;

  authToken!: any;
  role!: any;
  ticket = new TicketRespond();
  agent = new Agent();
  uTicket = new UpdateTicket();

  agents: Agent[] = [];

  assignedTo?: any;

  data = new Data();

  message?: any;

  hide: boolean = true;

  getStatusColor = getStatusColor;
  getPriorityColor = getPriorityColor;

  constructor(public _route:Router,private service: TicketService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.role = jwt_decode(this.authToken);
    this.getTicket(this.TicketId)
    this.service.RefreshRequired.subscribe(res=>{this.getTicket(this.TicketId)})
  }

  updateTicketForm = new FormGroup({
    status: new FormControl('', Validators.required),
    updateMessage: new FormControl(''),
    escalatedToAgentId: new FormControl('')
  })

  public updateTicket(): void {
    this.uTicket = Object.assign(this.uTicket, this.updateTicketForm.value)
    this.uTicket.ticketId=this.TicketId;
    if (this.uTicket.status === '') {
      Swal.fire({
        timer: 3000,
        text: "Fill the form",
        title: 'Error!',
        icon: 'error',
        showConfirmButton: false
      })
    } else {
      if (this.uTicket.status === 'Escalate') {
        if (this.uTicket.escalatedToAgentId !== null && this.uTicket.updateMessage !== null) {
          this.processUpdateTicket(this.uTicket);
        } else {
          Swal.fire({
            timer: 3000,
            text: "Choose agent and Ensure Reason is filled",
            title: 'Warning!',
            icon: 'warning',
            showConfirmButton: false
          })
        }
      } else {
        this.processUpdateTicket(this.uTicket)
      }
    }
  }

  public processUpdateTicket(ticketUpdate: UpdateTicket): void {
    this.spinner.show();
    this.service.updateTicket(ticketUpdate).subscribe(
      {
        next: (res: any) => {
          if (res) { this.spinner.hide() }
          this.message = res;
          Successful.successful(this.message);
          this.updateTicketForm.reset();
          
        }, error: (error: any) => {
          if (error) { this.spinner.hide() }
          Error.error(error);
        }
      }
    )
  }

  public get f() { return this.updateTicketForm.controls; }

  public onValueChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const value = target.value;

    if (value === "Escalate") {
      this.hide = false;
      this.getAgents(this.role.sub);
    } else {
      this.hide = true;
    }
  }

  private getTicket(ticketId: any): void {
    this.spinner.show();
    this.service.getTicket(ticketId).subscribe({
      next: (res: any) => {
        this.spinner.hide()
        this.ticket = Object.assign(this.ticket, res);
        this.data.assignedTo = this.ticket.assignedTo;
        this.data.description = this.ticket.description;
        this.data.accountId = this.role.accountId;
        this.getagent(this.ticket.assignedTo);
      }, error: (error: any) => {
        if (error) { this.spinner.hide(); }
        Error.error(error);
      }
    });
  }

  private getagent(agentId: any) {
    this.service.getAgent(agentId).subscribe({
      next: (res: any) => {
        this.agent = Object.assign(this.agent, res);
        this.assignedTo = this.agent.fullName;
      }, error: (error: any) => {
        Error.error(error);
      }
    })
  }

  private getAgents(companyId: any) {
    this.service.getAgents(companyId).subscribe({
      next: (res: any) => {
        this.agents = Object.assign(this.agents, res);
        console.log(this.agents)
      }, error: (error: any) => {
        Error.error(error);
      }
    })
  }

  public goBack():void{this._route.navigateByUrl("/tickets");}

}