<section class="section dashboard">
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">My Tickets</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>{{totalTickets}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">Closed Tickets</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>{{closedTickets}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">Open Tickets</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>{{openTickets}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">Escalated Tickets</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>{{escalatedTickets}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="card card-table overflow-auto">
                        <div class="card-body">
                            <h5 class="card-title">Latest Tickets</h5>
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Ticket Ref</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Priority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ticket of tickets">
                                        <td>#{{ticket.ticketId.substring(0,6)}}
                                        </td>
                                        <td>{{ticket.category}}</td>
                                        <td><span [ngClass]="getStatusColor(ticket.status.toString())">{{ticket.status}}</span></td>
                                        <td><span [ngClass]="getPriorityColor(ticket.priority.toString())">{{ticket.priority}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>