<main id="main" class="main">
    <div class="pagetitle">
        <h1>Settings</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">settings</li>
            </ol>
        </nav>
    </div>
    <section class="section">

        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card info-card admin-card">
                            <div class="card-body">
                                <app-company-settings *ngIf="role.role==='[ROLE_SUBSCRIBER]'"></app-company-settings>
                                <div class="container mt-4">
                                    <div class="row gy-4">
                                        <div class="col-lg-12">
                                            <div class="app-card">
                                                <div class="app-card-body p-2">
                                                    <h5 class="text-start mb-3">Change your password here</h5>
                                
                                                    <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
                                                        <div class="form-floating col-12 mb-4">
                                                            <input type="password" class="form-control" name="password" id="password" required
                                                                formControlName="password"
                                                                [class.is-invalid]="f['password'].invalid && f['password'].touched">
                                                            <label style="color: #6c757d;" for="password">New Password</label>
                                                            <div class="text-center" *ngIf="f['password'].invalid && f['password'].touched">
                                                                <p style="color: crimson;" class="small mt-1"
                                                                    *ngIf="f['password'].errors?.['required']">
                                                                    Password is
                                                                    required!</p>
                                                                <p style="color: crimson;" class="small mt-1"
                                                                    *ngIf="f['password'].errors?.['pattern']">
                                                                    Password must contain minimum of 8 characters,at least one uppercase
                                                                    letter,at least one
                                                                    lowercase letter
                                                                    ,at least one number and at least one special character</p>
                                                            </div>
                                                        </div>
                                
                                                        <div class="form-floating col-12">
                                                            <input type="password" class="form-control" name="repassword" id="repassword" required
                                                                formControlName="repassword"
                                                                [class.is-invalid]="f['repassword'].invalid && f['repassword'].touched">
                                                            <label style="color: #6c757d;" for="password">Confirm Password</label>
                                                            <div class="text-center" *ngIf="f['repassword'].invalid && f['repassword'].touched">
                                                                <p style="color: crimson;" class="small mt-1"
                                                                    *ngIf="f['repassword'].errors?.['required']">
                                                                    Password is
                                                                    required!</p>
                                                                <p style="color: crimson;" class="small mt-1"
                                                                    *ngIf="f['repassword'].errors?.['matchpassword']">Password don't match</p>
                                                            </div>
                                                        </div>
                                                        <div class="text-end col-12"><button [disabled]="changePasswordForm.invalid" type="submit">Submit</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
