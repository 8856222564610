import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainpageComponent } from './pages/mainpage/mainpage.component';
import { CompanyCreateAccountComponent } from './pages/company-create-account/company-create-account.component';
import { CompanyLoginComponent } from './pages/company-login/company-login.component';
import { CompanyDashboardComponent } from './pages/company-dashboard/company-dashboard.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RegisterAdminComponent } from './pages/register-admin/register-admin.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AuthGuard } from './util/authgaurd.service';
import { RegisterUsersComponent } from './pages/register-users/register-users.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { TicketsComponent } from './pages/tickets/tickets.component';
import { LoginComponent } from './pages/login/login.component';
import { AdminTicketsComponent } from './pages/admin-tickets/admin-tickets.component';

const routes: Routes = [
  //{path:"",component:MainpageComponent},
  {path:"create-account",component:CompanyCreateAccountComponent},
  {path:"comp-login",component:CompanyLoginComponent},
  {path:"dashboard",component:DashboardComponent,canActivate:[AuthGuard]},
  {path:"register-admin",component:RegisterAdminComponent,canActivate:[AuthGuard]},
  {path:"subscription",component:SubscriptionsComponent,canActivate:[AuthGuard]},
  {path:"settings",component:SettingsComponent,canActivate:[AuthGuard]},
  {path:"register-user",component:RegisterUsersComponent,canActivate:[AuthGuard]},
  {path:"reports",component:ReportsComponent,canActivate:[AuthGuard]},
  {path:"tickets",component:TicketsComponent,canActivate:[AuthGuard]},
  {path:"",component:LoginComponent},
  {path:"admin-tickets",component:AdminTicketsComponent,canActivate:[AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
