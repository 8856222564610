import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { RegisterAdmin, UpdatePassword } from '../models/models';
import { Constants, httpOptionsWithAuth } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class SubscriberAdminService {
  constructor(private _httpClient: HttpClient) { }

  private refreshRequired = new Subject<void>();
  get RefreshRequired() { return this.refreshRequired; }

  public registerAdmin(companyId: any, admin: RegisterAdmin): Observable<any> {
    return this._httpClient.post(Constants.GATEWAY_URL + "company/registerAdministrator/" + companyId, admin, httpOptionsWithAuth)
      .pipe(tap(() => this.refreshRequired.next()));
  }

  public getAdmins(companyId: any): Observable<any> {
    return this._httpClient.get(Constants.GATEWAY_URL + "company/get-registered-admins/" + companyId, httpOptionsWithAuth);
  }

  public updatePassword(password: UpdatePassword, role: any): Observable<any> {
    return this._httpClient.put(Constants.GATEWAY_URL + "company/update-password/" + role.replace(/\[|\]/g, ''), password, httpOptionsWithAuth);
  }
}