import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegisterUser } from 'src/app/util/models/models';
import { SharedService } from 'src/app/util/services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit{

  user = new RegisterUser();
  users: RegisterUser[] = [];

  constructor(private sharedService: SharedService, private spinner: NgxSpinnerService) { }

  registerUserForm = new FormGroup({
    fullName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])),
    contactNumber: new FormControl('', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^(\\+27|0|0[1-5])[6-8][0-9]{8}$")])),
    lastName: new FormControl('', Validators.required),
    position: new FormControl('', Validators.required),
    role: new FormControl('', Validators.required)
  })

  public get f() { return this.registerUserForm.controls; }

  ngOnInit(): void {
    this.sharedService.getArray().subscribe((data)=>{
      this.users=data;
    })
  }

  public addUser(): void {
    this.spinner.show();
    this.user = Object.assign(this.user, this.registerUserForm.value);
    let position = this.users.findIndex(pos=>pos.email===this.user.email);
    if(position===-1){
      this.spinner.hide()
      this.sharedService.addData(this.user)
      Swal.fire({
        timer: 1000,
        title: 'Successful',
        text: 'You have added ' + this.user.fullName,
        icon: 'success',
        showConfirmButton: false
      })
      this.registerUserForm.reset();
    }else{
      this.spinner.hide()
      Swal.fire({
        timer: 1000,
        title: 'Failed',
        text: this.user.fullName+' Already added',
        icon: 'error',
        showConfirmButton: false
      })
      this.registerUserForm.reset();
    }
      
  }
}
