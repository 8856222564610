import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { StorageService } from './storage';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    constructor(private storage: StorageService, private router: Router) { }
    canActivate(): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.storage.isLoggedIn()) { return true; }
        else { this.router.navigateByUrl("/login"); return false; }
    }
}