import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/util/storage';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  constructor(@Inject(DOCUMENT) private document: Document,private _router:Router,private _store_service:StorageService){}

  authToken!:any;
  name!:any;

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.name = jwt_decode(this.authToken);
  }

  public sidebarToggle(): void { this.document.body.classList.toggle('toggle-sidebar');}

  public  logout():void{
    this._store_service.clean();
    location.reload();
  }

}
