import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { error } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { Error } from 'src/app/util/error';
import { Login } from 'src/app/util/models/models';
import { AgentCustomerService } from 'src/app/util/services/agent-customer.service';
import { StorageService } from 'src/app/util/storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit,AfterViewInit {

  @ViewChild('passwordInput') passwordInput!: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
    const passwordInput = this.passwordInput.nativeElement;
    const showPasswordCheckbox = document.getElementById('showPassword') as HTMLInputElement;

    showPasswordCheckbox.addEventListener('change', function () {
      if (showPasswordCheckbox.checked) {
        passwordInput.setAttribute('type', 'text');
      } else {
        passwordInput.setAttribute('type', 'password');
      }
    });
  }

  login = new Login();
  isLoggedIn: boolean = false;
  authToken!: any;
  name:string='authToken';

  constructor(private _spinner: NgxSpinnerService, private _service: AgentCustomerService,
    private _storage: StorageService, private _router: Router) { }

  loginForm = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])),
    password: new FormControl('', Validators.compose([Validators.required]))
  })

  public get v() { return this.loginForm.controls; }

  ngOnInit(): void {
    if (this._storage.isLoggedIn()) {
      this.isLoggedIn = true;
      this._router.navigateByUrl("/dashboard")
    }
  }

  public Login(): void {
    this._spinner.show()
    this.login = Object.assign(this.login, this.loginForm.value);
    this._service.login(this.login).subscribe(res => {
      if (res) { this._spinner.hide }
      this.authToken = res;
      this._storage.saveUser(this.authToken.message);
      this.isLoggedIn = true;
      this._router.navigateByUrl("/dashboard");
    }, error => {
      if (error) { this._spinner.hide() }
      if (error.status !== 0) {
        Swal.fire({
          timer:2000,
          title:'Error',
          icon:'error',
          text:error.error.message,
          position:'center',
          showConfirmButton:false
        })
      } else {
          Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              title:'Service Unavailable',
              didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
          })
      }
    })
  }

}