import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Error } from 'src/app/util/error';
import { Login } from 'src/app/util/models/models';
import { CompAuthService } from 'src/app/util/services/comp-auth.service';
import { StorageService } from 'src/app/util/storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-company-login',
  templateUrl: './company-login.component.html',
  styleUrls: ['./company-login.component.css']
})
export class CompanyLoginComponent implements OnInit,AfterViewInit {

  @ViewChild('passwordInput') passwordInput!: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
    const passwordInput = this.passwordInput.nativeElement;
    const showPasswordCheckbox = document.getElementById('showPassword') as HTMLInputElement;

    showPasswordCheckbox.addEventListener('change', function () {
      if (showPasswordCheckbox.checked) {
        passwordInput.setAttribute('type', 'text');
      } else {
        passwordInput.setAttribute('type', 'password');
      }
    });
  }

  loginRequest = new Login();
  authToken!: any;
  isLoggedIn: boolean = false;

  constructor(private storageService: StorageService, private _authService: CompAuthService,
    private _spinner: NgxSpinnerService, private router: Router) { }

  signinForm = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])),
    password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)]))
  })

  public get f() { return this.signinForm.controls; }

  ngOnInit(): void {
    if (this.storageService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.router.navigateByUrl("/dashboard")
    }
  }

  public login(): void {
    this.loginRequest = Object.assign(this.loginRequest, this.signinForm.value)
    this._spinner.show();

    this._authService.login(this.loginRequest).subscribe(res => {
      if (res) { this._spinner.hide() }
      this.authToken = res;
      this.storageService.saveUser(this.authToken.message);
      this.isLoggedIn = true;
      this.router.navigateByUrl("/dashboard");
    }, error => {
      console.log(error)
      if (error) { this._spinner.hide() }
      if (error.status !== 0) {
        Swal.fire({
          timer:2000,
          title:'Error',
          icon:'error',
          text:error.error.message,
          position:'center',
          showConfirmButton:false
        })
      } else {
          Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              title:'Service Unavailable',
              didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
          })
      }
    })
  }
}