export function getStatusColor(status: string): string {
    switch (status.toLowerCase()) {
      case 'closed':
        return 'badge bg-secondary'; // Red color for closed status
      case 'open':
        return 'badge bg-success'; // Green color for open status
      case 'escalated':
        return 'badge bg-purple'; // Purple color for escalated status
      default:
        return 'badge bg-secondary'; // Gray color for unknown status
    }
  }
  
  export function getPriorityColor(priority: string): string {
    switch (priority.toLowerCase()) {
      case 'low':
        return 'badge bg-primary'; // Green color for low priority
      case 'medium':
        return 'badge bg-warning'; // Yellow color for medium priority
      case 'high':
        return 'badge bg-danger'; // Red color for high priority
      default:
        return 'badge bg-secondary'; // Gray color for unknown priority
    }
  }
  