<main id="main" class="main">
    <div class="pagetitle">
        <h1>Register Users</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">register users</li>
            </ol>
        </nav>
    </div>
    <section class="section">

        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card info-card admin-card">
                            <div class="card-body">
                                <div class="container mt-4">

                                    <div class="row gy-4">
                                        <div class="col-lg-12">
                                            <nav id="orders-table-tab"
                                                class="orders-table-tab app-nav-tabs nav shadow-sm flex-column flex-sm-row mb-4">
                                                <a class="flex-sm-fill text-sm-center nav-link active"
                                                    id="registered-tab" data-bs-toggle="tab" href="#registered"
                                                    role="tab" aria-controls="registered" aria-selected="true">
                                                    REGISTERED USERS</a>
                                                <a class="flex-sm-fill text-sm-center nav-link" id="experience-tab"
                                                    data-bs-toggle="tab" href="#register" role="tab"
                                                    aria-controls="register" aria-selected="false">REGISTER USER</a>
                                            </nav>

                                            <div class="tab-content" id="orders-table-tab-content">
                                                <div class="tab-pane fade show active" id="registered" role="tabpanel"
                                                    aria-labelledby="registered">
                                                    <div class="app-card">
                                                        <div class="app-card-body p-2">
                                                            <h5 class="card-title">List of Users</h5>
                                                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Full Name</th>
                                                                        <th scope="col">Email</th>
                                                                        <th scope="col">Position</th>
                                                                        <th scope="col">Role</th>
                                                                        <th scope="col">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let user of registeredUsers">
                                                                        <td>{{user.fullName}}</td>
                                                                        <td>{{user.email}}</td>
                                                                        <td>{{user.position}}</td>
                                                                        <td>{{user.role}}</td>
                                                                        <td><a (click)="resetPassword(user.accountId,user.role)" style="text-decoration:underline;color: blue;cursor: pointer;">Reset Password</a></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade show" id="register" role="tabpanel"
                                                    aria-labelledby="register">
                                                    <div class="app-card">
                                                        <div class="app-card-body p-2">
                                                            <h5 class="card-title">{{users.length>0 ? "List of Users":"Click Add button to start adding users"}}</h5>
                                                            <div *ngFor="let data of users" class="alert alert-success" role="alert">
                                                                {{data.fullName}} — {{data.role}}
                                                            </div>
                                                            <div class="text-end col-12"><button data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal" type="button">Add
                                                                    User</button></div>
                                                            <div *ngIf="users.length>0" class="text-end col-12"><button
                                                                         (click)="registerUsers()" type="button">Register Added Users ({{users.length}})</button></div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="modal fade" id="exampleModal" tabindex="-1"
                                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title modal-title-centered"
                                                                    id="exampleModalLabel">Register User</h5>
                                                                <button class="btn-close" data-bs-dismiss="modal"
                                                                    aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <app-register></app-register>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>