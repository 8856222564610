import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Validation } from 'src/app/util/validation';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css']
})
export class CompanySettingsComponent {

  changePasswordForm!:FormGroup;

  constructor(private formBuilder:FormBuilder,private spinner:NgxSpinnerService){

    this.changePasswordForm = this.formBuilder.group({
      password: new FormControl('',Validators.compose([Validators.required,Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,15}')])),
      repassword: new FormControl('',Validators.compose([Validators.required,Validators.minLength(8)]))
    },{validators:Validation.matchpassword('password','repassword')})
  }


  public get f() { return this.changePasswordForm.controls; }

  public changePassword():void{
    
  }
}
