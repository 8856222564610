import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminResponse, RegisterAdmin } from 'src/app/util/models/models';
import { SubscriberAdminService } from 'src/app/util/services/subscriber-admin.service';
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-register-admin',
  templateUrl: './register-admin.component.html',
  styleUrls: ['./register-admin.component.css']
})
export class RegisterAdminComponent implements OnInit, OnDestroy {

  admin = new RegisterAdmin();
  adminResponse: AdminResponse[] = [];

  companyId: String = " ";
  message: any;
  value: any;
  value1: any;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private _spinner: NgxSpinnerService, private _registerService: SubscriberAdminService) { }

  ngOnInit(): void {
    this.value = sessionStorage.getItem('auth-user');
    this.value1 = jwt_decode(this.value);

    this.companyId = this.value1.companyId;
    this.getListOfAdmins(this.companyId)

    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      processing: true
    };

    //this._registerService.RefreshRequired.subscribe(res=>{this.getListOfAdmins(this.companyId)})
  }

  registerAdminForm = new FormGroup({
    fullName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])),
    contactNumber: new FormControl('', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^(\\+27|0|0[1-5])[6-8][0-9]{8}$")])),
    lastName: new FormControl('', Validators.required),
    position: new FormControl('', Validators.required)
  })

  public get f() { return this.registerAdminForm.controls; }

  public registerAdmin(): void {
    this.admin = Object.assign(this.admin, this.registerAdminForm.value);
    this._spinner.show()
    this._registerService.registerAdmin(this.companyId, this.admin).subscribe(
      res => {
        this.message = res;
        if (res) { this._spinner.hide() }
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Success',
          text: this.message.message,
          showConfirmButton: false,
          timer: 2000
        })
        this.registerAdminForm.reset();
        location.reload();
      }, error => {
        if (error) { this._spinner.hide(); }
        if (error.status !== 0) {
          Swal.fire(
            {
              position: 'center',
              icon: "error",
              title: 'Failed',
              text: error.error,
              showConfirmButton: false,
              timer: 5000
            }
          )
        } else {
          console.log("Service Unavailable");
        }
      }
    )
  }


  private getListOfAdmins(companyId: any): void {
    this._spinner.show();
    this._registerService.getAdmins(companyId).subscribe(res => {
      if (res) { this._spinner.hide() }
      this.adminResponse = Object.assign(this.adminResponse, res);
      this.dtTrigger.next(null);
    }, error => {
      console.log(error)
      if (error) { this._spinner.hide(); }
      if (error.status !== 0) {
        Swal.fire(
          {
            position: 'center',
            icon: "error",
            title: 'Failed',
            text: error.error,
            showConfirmButton: false,
            timer: 5000
          }
        )
      } else {
        console.log("Service Unavailable");
      }
    }
    )
  }

  ngOnDestroy(): void { this.dtTrigger.unsubscribe(); }
}