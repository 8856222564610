import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';
import { Error } from 'src/app/util/error';
import { UpdatePassword } from 'src/app/util/models/models';
import { SettingsService } from 'src/app/util/services/changepassword-service';
import { Successful } from 'src/app/util/successful';
import { Validation } from 'src/app/util/validation';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit{
  authToken!:any;
  role!:any;
  changePasswordForm!:FormGroup;
  updatePassword = new UpdatePassword();
  message!:any;

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.role = jwt_decode(this.authToken);
  }

  constructor(private formBuilder:FormBuilder,private spinner:NgxSpinnerService,private _service:SettingsService){
    this.changePasswordForm = this.formBuilder.group({
      password: new FormControl('',Validators.compose([Validators.required,Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,15}')])),
      repassword: new FormControl('',Validators.compose([Validators.required,Validators.minLength(8)]))
    },{validators:Validation.matchpassword('password','repassword')})
  }

  public get f() { return this.changePasswordForm.controls; }

  public changePassword():void{
    this.spinner.show();
    this.updatePassword.accountId=this.role.accountId;
    this.updatePassword.password=this.changePasswordForm.get('password')?.value;
    this.updatePassword.role=this.role.role;

    this._service.changePassword(this.updatePassword).subscribe(
      res=>{
        if(res){this.spinner.hide()}
        this.message=res;
        Successful.successful(this.message);
        this.changePasswordForm.reset();
      },error=>{
        if(error){this.spinner.hide()}
        Error.error(error)
      }) 
  }

}
