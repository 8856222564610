<section class="section dashboard">
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-3">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">Total Users</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>{{registeredUsers.length}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">Agent Users</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>{{agent}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">End Users</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>{{enduser}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

                <div class="col-12">
                    <div class="card card-table overflow-auto">
                        <div class="card-body">
                            <h5 class="card-title">List of Users</h5>
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Full Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Postion</th>
                                        <th scope="col">Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of registeredUsers">
                                        <td>{{user.fullName}}</td>
                                        <td>{{user.email}}</td>
                                        <td>{{user.position}}</td>
                                        <td>{{user.role}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
