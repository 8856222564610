<div class="container-fluid">
    <div style="display: flex; justify-content: center;">
        <div class="row">
            <div>
                <img src="../../../assets/img/SDT New logo-01 (11).png" height="150px" width="250px" alt="Service Desk">
                <!--<br/><small><span class="Service-Desk" style="font-weight: bold;"> Service Desk</span></small>-->
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <form [formGroup]="loginForm" (ngSubmit)="Login()" class="col-md-6 mx-auto">
            <div class="form-group">
                <input type="email" class="form-control" id="email" formControlName="email"
                    placeholder="Enter work email" required [class.is-invalid]="v.email.invalid && v.email.touched">
                <div class="text-center" *ngIf="v.email.invalid && v.email.touched">
                    <p style="color: crimson;" class="small mt-1" *ngIf="v.email.errors?.['required']">Email is
                        required!</p>
                </div>
            </div>
            <div class="form-group">
                <input type="password" class="form-control form-control-user" formControlName="password"
                    placeholder="Password" required [class.is-invalid]="v.password.invalid && v.password.touched"
                    #passwordInput>
                <div class="text-center" *ngIf="v.password.invalid && v.password.touched">
                    <p style="color: crimson;" class="small mt-1" *ngIf="v.password.errors?.['required']">
                        Password is required!</p>
                </div>
                <div class="form-text">
                    <input type="checkbox" id="showPassword"> Show Password
                </div>
            </div>

            <button type="submit" class="btn btn-primary btn-user btn-block mb-3">
                Login
            </button>
        </form>
    </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>