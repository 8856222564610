<main id="main" class="main">
    <div class="pagetitle">
        <h1>Welcome to Service Desk&nbsp;&nbsp;</h1>
    </div>
    <app-company-dashboard *ngIf="role.role==='[ROLE_COMPANY]'"></app-company-dashboard>
    <app-admin-dashboard *ngIf="role.role==='[ROLE_ADMIN]'"></app-admin-dashboard>
    <app-agent-dashboard *ngIf="role.role==='[ROLE_AGENT]'"></app-agent-dashboard>
    <app-enduser-dashboard *ngIf="role.role==='[ROLE_END_USER]'"></app-enduser-dashboard>
</main>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>