import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Subject, tap, throwError } from 'rxjs';
import { AdminTickets, RegisterAdmin, RequestService, UpdateTicket } from '../models/models';
import { Constants, httpOptionsWithAuth } from '../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    constructor(private httpClient: HttpClient) { }

    private refreshrequired = new Subject<void>();
    get RefreshRequired() { return this.refreshrequired; }

    public getAllTickets(companyId: any): Observable<any> {
        return this.httpClient.get(Constants.GATEWAY_URL + `ticket/get-tickets/${companyId}`, httpOptionsWithAuth);
    }

    public getResolvedTickets(companyId: any): Observable<any> {
        return this.httpClient.get(Constants.GATEWAY_URL + `ticket/get-resolved-tickets/${companyId}`, httpOptionsWithAuth);
    }

    public getUserTickets(userId: any): Observable<any> {
        return this.httpClient.get(Constants.GATEWAY_URL + "ticket/get-user-tickets/" + userId, httpOptionsWithAuth);
    }

    public getAgentTickets(userId: any): Observable<any> {
        return this.httpClient.get(Constants.GATEWAY_URL + "ticket/get-agent-tickets/" + userId, httpOptionsWithAuth);
    }

    public requestService(companyId: any, service: RequestService): Observable<any> {
        return this.httpClient.post(Constants.GATEWAY_URL + "ticket/request-service/" + companyId, service, httpOptionsWithAuth)
            .pipe(tap(() => { this.refreshrequired.next() }))
    }

    public getTicket(ticketId: any): Observable<any> {
        return this.httpClient.get(Constants.GATEWAY_URL + "ticket/get-ticket/" + ticketId, httpOptionsWithAuth);
    }

    public getAgent(agentId: any): Observable<any> {
        return this.httpClient.get(Constants.GATEWAY_URL + "users/get-agent/" + agentId, httpOptionsWithAuth);
    }

    public getAgents(companyId: any): Observable<any> {
        return this.httpClient.get(Constants.GATEWAY_URL + `users/get-agents/${companyId}`, httpOptionsWithAuth);
    }

    public updateTicket(updateTicket: UpdateTicket): Observable<any> {
        return this.httpClient.put(Constants.GATEWAY_URL + "ticket/update-ticket", updateTicket, httpOptionsWithAuth)
            .pipe(tap(() => { this.refreshrequired.next() }))
    }

    public adminTickets(companyId:any):Observable<AdminTickets[]>{
        return this.httpClient.get<AdminTickets[]>(Constants.GATEWAY_URL+`ticket/admin-tickets/${companyId}`,httpOptionsWithAuth);
    }

    public reassignTicket(ticketId:any,agentId:any):Observable<any>{
        return this.httpClient.put(Constants.GATEWAY_URL+`ticket/reassign-ticket/${ticketId}/${agentId}`,httpOptionsWithAuth)
        .pipe(tap(() => { this.refreshrequired.next() }));
    }
}