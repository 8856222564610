import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignUpCompany } from 'src/app/util/models/models';
import { CompAuthService } from 'src/app/util/services/comp-auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-company-create-account',
  templateUrl: './company-create-account.component.html',
  styleUrls: ['./company-create-account.component.css']
})
export class CompanyCreateAccountComponent implements AfterViewInit {

  @ViewChild('passwordInput') passwordInput!: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
    const passwordInput = this.passwordInput.nativeElement;
    const showPasswordCheckbox = document.getElementById('showPassword') as HTMLInputElement;

    showPasswordCheckbox.addEventListener('change', function () {
      if (showPasswordCheckbox.checked) {
        passwordInput.setAttribute('type', 'text');
      } else {
        passwordInput.setAttribute('type', 'password');
      }
    });
  }

  signup = new SignUpCompany();
  message!: any;

  constructor(private _spinner: NgxSpinnerService, private authService: CompAuthService,private router:Router) { }

  signupForm = new FormGroup({
    companyName: new FormControl('', Validators.required),
    companyEmail: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])),
    contactNumber: new FormControl('', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("^(\\+27|0|0[1-5])[6-8][0-9]{8}$")])),
    password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,15}')]))
  })

  get f() { return this.signupForm.controls; }

  public singup(): void {
    this.signup = Object.assign(this.signup, this.signupForm.value);
    this._spinner.show();
    this.authService.register(this.signup).subscribe(
      res => {
        if (res) { this._spinner.hide(); }
        this.message = res;
        Swal.fire(
          {
            position: 'center',
            icon: 'success',
            title: 'Account Registered Successful',
            text: this.message.message,
            showConfirmButton: false,
            timer: 5000
          }
        )
        this.signupForm.reset();
        this.router.navigateByUrl("/comp-login");
      }, error => {
        if (error) { this._spinner.hide(); }
        if (error.status !== 0) {
          Swal.fire(
            {
              position: 'center',
              icon: "error",
              title: 'Failed',
              text: error.error,
              showConfirmButton: false,
              timer: 5000
            }
          )
        } else {
          console.log("Service Unavailable");
        }
      }
    )
  }
}