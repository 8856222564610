import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { Users } from 'src/app/util/models/models';
import { UsersService } from 'src/app/util/services/users.service';
import jwt_decode from 'jwt-decode';
import { Error } from 'src/app/util/error';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit, OnDestroy {

  registeredUsers: Users[] = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  authToken!: any;
  companyId!: any;
  agent: number = 0;
  enduser: number = 0;

  constructor(private _service: UsersService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.authToken = sessionStorage.getItem('auth-user');
    this.companyId = jwt_decode(this.authToken);

    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      processing: true
    };
    this.getUsers(this.companyId.companyId);


  }

  private getUsers(companyId: any): void {
    this.spinner.show();
    this._service.getusers(companyId).subscribe(res => {
      if (res) { this.spinner.hide() }
      this.registeredUsers = res;
      this.dtTrigger.next(null);
      for (let user of this.registeredUsers) {
        if (user.role === "Agent") {
          this.agent = this.agent + 1
        }
        if (user.role === "End-User") {
          this.enduser = this.enduser + 1;
        }
      }
    }, error => {
      if (error) { this.spinner.hide() }
      Error.error(error)
    })
  }

  ngOnDestroy(): void { this.dtTrigger.unsubscribe(); }

}
