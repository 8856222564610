import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ErrorHandler } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler{

  constructor() { }

  handleError(error: any): void {
        let errorMessage = '';
        if (error instanceof HttpErrorResponse) {
            // Handle HTTP errors
            const httpError = error as HttpErrorResponse;

            if (httpError.error instanceof ErrorEvent) {
                //client-error error
                errorMessage = `Error: ${error.error.message}`;

            } else {
                //server-side error;
                errorMessage = `Error Code ${error.status}\nMessage: ${error.message}`;
            }
        }
        window.alert(errorMessage)
    }

  public handleHttpError(error: HttpErrorResponse):Observable<never> {
    let errorMessage='';
    if(error.error instanceof ErrorEvent){
      //client-error error
      errorMessage=`Error: ${error.error.message}`;
    }else{
      //server-side error;
      errorMessage=`Error Code ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage)
    return throwError(()=>errorMessage);
  }
}
