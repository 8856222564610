import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/util/storage';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit{

  authToken!:any;
  role!:any;

  constructor(private _store_service:StorageService){}

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.role = jwt_decode(this.authToken);
  }

  public  logout():void{
    this._store_service.clean();
    location.reload();
  }

}
