<section class="section dashboard">
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">Total Users</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>1</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">Admin Users</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>2</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">Agents Users</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>1</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card info-card">
                        <div class="card-body">
                            <h5 class="card-title">Normal Users</h5>
                            <div class="d-flex align-items-center">
                                <div class="ps-1">
                                    <h6>2</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="card card-table overflow-auto">
                        <div class="card-body">
                            <h5 class="card-title">List of Users</h5>
                            <table datatable class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">Full Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Department</th>
                                        <th scope="col">Position</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>