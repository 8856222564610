import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TicketRespond } from 'src/app/util/models/models';
import jwt_decode from 'jwt-decode';
import { TicketService } from 'src/app/util/services/ticket.service';
import { Error } from 'src/app/util/error';
import { EChartsOption } from 'echarts';
import { NgxEchartsModule, NGX_ECHARTS_CONFIG } from 'ngx-echarts';
import { UsersService } from 'src/app/util/services/users.service';

interface GroupedTickets {
  [key: string]: {
    Medium: number;
    Low: number;
    High: number;
  };
}

class Users{
  admin?:any;
  agents?:any;
  endUsers?:any;
}

@Component({
  standalone: true,
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  template: `<div echarts [options]="chartOptions" class="demo-chart"></div>`,
  imports: [NgxEchartsModule],
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useFactory: () => ({ echarts: () => import('echarts') })
    },
  ]
})
export class ReportsComponent implements OnInit {
  [x: string]: any;

  chartOption = {};
  resolvedChartOptions={};
  usersChartOptions={};
  users?:Users;


  tickets: TicketRespond[] = [];
  resolvedTickets: TicketRespond[] = [];
  authToken!: any;
  companyId!: any;
  dataPoints = [];
  groupedData = {};

  highPriorityData = [];
  mediumPriorityData = [];
  lowPriorityData = [];

  groupedTickets: GroupedTickets = {};
  

  constructor(private _service: TicketService, private spinner: NgxSpinnerService,private _users:UsersService) { }

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.companyId = jwt_decode(this.authToken);
    this.getTickets(this.companyId.companyId);
    this.getResolvedTickets(this.companyId.companyId);
    this.getUserData(this.companyId.companyId);
  }

  private getTickets(companyId: any): void {
    this.spinner.show();
    this._service.getAllTickets(companyId).subscribe({
      next: (res: any) => {
        if (res) { this.spinner.hide() }
        this.tickets = res;
        const groupedTickets = this.tickets.reduce((result: GroupedTickets, ticket) => {
          const createdAt = new Date(ticket.createdAt).toLocaleDateString();
          if (!result[createdAt]) {
            result[createdAt] = {
              Medium: 0,
              Low: 0,
              High: 0,
            };
          }
          const priority: keyof typeof result[typeof createdAt] = ticket.priority as keyof typeof result[typeof createdAt];
          result[createdAt][priority]++;
          return result;
        }, {} as GroupedTickets);

        const xAxisLabels = [];
        const mediumPriorityData = [];
        const lowPriorityData = [];
        const highPriorityData = [];

        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        for (let i = thirtyDaysAgo; i <= new Date(); i.setDate(i.getDate() + 1)) {
          const date = i.toLocaleDateString();
          xAxisLabels.push(date);
          const mediumCount = groupedTickets[date] ? groupedTickets[date].Medium : 0;
          const lowCount = groupedTickets[date] ? groupedTickets[date].Low : 0;
          const highCount = groupedTickets[date] ? groupedTickets[date].High : 0;
          mediumPriorityData.push(mediumCount);
          lowPriorityData.push(lowCount);
          highPriorityData.push(highCount);
        }

        this.chartOption = {
          title: {
            text: 'New Tickets',
            textStyle: {
              fontFamily: 'Arial, sans-serif',
              fontSize: 16, 
              fontWeight: 'normal', 
            },
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['Medium Priority', 'Low Priority', 'High Priority'],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {
                name: 'new_tickets',
              }
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.generateLast30DaysLabels()
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: 'Medium Priority',
              type: 'line',
              data: mediumPriorityData,
              itemStyle: {
                color: 'yellow'
              }
            },
            {
              name: 'Low Priority',
              type: 'line',
              data: lowPriorityData,
              itemStyle: {
                color: 'blue'
              }
            },
            {
              name: 'High Priority',
              type: 'line',
              data: highPriorityData,
              itemStyle: {
                color: 'red'
              }
            },
          ],
        };
      }, error: (err: any) => {
        if (err) { this.spinner.hide() }
        Error.error(err)
      }
    })
  }

  public getResolvedTickets(companyId:any): void{
    this.spinner.show();
    this._service.getResolvedTickets(companyId).subscribe({
      next:(res:any)=>{
        this.resolvedTickets=res;

        const groupedTickets = this.resolvedTickets.reduce((result: GroupedTickets, ticket) => {
          const createdAt = new Date(ticket.createdAt).toLocaleDateString();
          if (!result[createdAt]) {
            result[createdAt] = {
              Medium: 0,
              Low: 0,
              High: 0,
            };
          }
          const priority: keyof typeof result[typeof createdAt] = ticket.priority as keyof typeof result[typeof createdAt];
          result[createdAt][priority]++;
          return result;
        }, {} as GroupedTickets);

        const xAxisLabels = [];
        const mediumPriorityData = [];
        const lowPriorityData = [];
        const highPriorityData = [];

        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        for (let i = thirtyDaysAgo; i <= new Date(); i.setDate(i.getDate() + 1)) {
          const date = i.toLocaleDateString();
          xAxisLabels.push(date);
          const mediumCount = groupedTickets[date] ? groupedTickets[date].Medium : 0;
          const lowCount = groupedTickets[date] ? groupedTickets[date].Low : 0;
          const highCount = groupedTickets[date] ? groupedTickets[date].High : 0;
          mediumPriorityData.push(mediumCount);
          lowPriorityData.push(lowCount);
          highPriorityData.push(highCount);
        }

        this.resolvedChartOptions = {
          title: {
            text: 'Resolved Tickets',
            textStyle: {
              fontFamily: 'Arial, sans-serif',
              fontSize: 12, 
              fontWeight: 'normal', 
            },
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['Medium Priority', 'Low Priority', 'High Priority'],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {
                name: 'resolved_tickets',
              }
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.generateLast30DaysLabels()
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: 'Medium Priority',
              type: 'line',
              data: mediumPriorityData,
              itemStyle: {
                color: 'yellow'
              }
            },
            {
              name: 'Low Priority',
              type: 'line',
              data: lowPriorityData,
              itemStyle: {
                color: 'blue'
              }
            },
            {
              name: 'High Priority',
              type: 'line',
              data: highPriorityData,
              itemStyle: {
                color: 'red'
              }
            },
          ],
        };
      },error:(err:any)=>{
        Error.error(err);
      }
    })
  }

  public getUserData(companyId:any):void{
    this._users.getusersdata(companyId).subscribe({
      next:(res:any)=>{
        this.users=res;
        this.usersChartOptions = {
          title: {
            text: 'Total users',
            textStyle: {
              fontFamily: 'Arial, sans-serif',
              fontSize: 20, 
              fontWeight: 'normal', 
            },
          },
          toolbox: {
            feature: {
              saveAsImage: {
                name: 'users',
              }
            }
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center',
          },
          series: [
            {
              name: 'User Typle',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: this.users?.admin, name: 'Admin' },
                { value: this.users?.agents, name: 'Agent Users' },
                { value: this.users?.endUsers, name: 'End Users' },
                
              ]
            }
          ]
        };
      },
      error:(err:any)=>{
        Error.error(err);
      }
    })
  }

  private generateLast30DaysLabels(): string[] {
    const today = new Date();
    const labels: string[] = [];

    for (let i = 30; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);

      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });

      labels.push(`${day} ${month}`);
    }
    return labels;
  }
}