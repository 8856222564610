<app-open-chat [TicketId]="ticketId" *ngIf="openChat"></app-open-chat>
<main id="main" class="main" *ngIf="!openChat">
    <div class="pagetitle">
        <h1>Tickets</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">tickets</li>
            </ol>
        </nav>
    </div>
    <section class="section">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card info-card admin-card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h5 class="mt-3 mb-4">Tickets</h5>
                                        <div class="row mt-3 mb-3">
                                            <div class="col-6 col-md-3">
                                                <select type="text" class="form-select" id="statusSelect"
                                                    (change)="onStatusChange($event)"
                                                    aria-label="Default select example">
                                                    <option value="Open">Open</option>
                                                    <option value="Closed">Closed</option>
                                                    <option value="all">All</option>
                                                </select>
                                            </div>
                                            <div class="col-6 col-md-3">
                                                <select type="text" class="form-select" id="prioritySelect"
                                                    (change)="onPriorityChange($event)"
                                                    aria-label="Default select example">
                                                    <option value="all">Priority</option>
                                                    <option value="High">High</option>
                                                    <option value="Medium">Medium</option>
                                                    <option value="Low">Low</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6 mb-3 mt-1">
                                                <button *ngIf="token.role==='[ROLE_END_USER]' || token.role==='[ROLE_AGENT]'" type="button"
                                                    class="btn btn-primary float-end" data-bs-toggle="modal"
                                                    data-bs-target="#myModal">New Ticket</button>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-lg-12">
                                                <div class="px-2 scroll">
                                                    <div class="card text-black mt-1" >
                                                        <div *ngIf="filterdTickets.length===0" class="card-body">
                                                            <div class="mt-5 d-flex justify-content-center">
                                                                <p>No Tickets</p><br />
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngFor="let ticket of filterdTickets | paginate:{itemsPerPage:itemsPerPage,currentPage:p}"
                                                        class="card mt-1">
                                                        <div class="card-body">
                                                            <div class="card-title d-flex justify-content-between">
                                                                <h5>{{ticket.category}}</h5>
                                                                <h5 class="text-muted"><small>{{ticket.createdAt |
                                                                        date:'dd MMM YYYY HH:MM'}}</small></h5>
                                                            </div>
                                                            <div class="row">
                                                                <p>{{ticket.description}}</p>
                                                            </div>
                                                            <span
                                                            [ngClass]="getStatusColor(ticket.status.toString())">{{ticket.status}}</span>&nbsp;
                                                            <span
                                                            [ngClass]="getPriorityColor(ticket.priority.toString())">{{ticket.priority}}</span>&nbsp;
                                                            <span
                                                                class="badge bg-secondary">#{{ticket.ticketId.substring(0,4)}}</span>

                                                            <button [disabled]="ticket.status!=='Open'" *ngIf="token.role==='[ROLE_END_USER]'" type="button"
                                                                (click)="openchat(ticket.ticketId)"
                                                                class="btn btn-secondary float-end">Chat</button>
                                                            <button [disabled]="ticket.status!=='Open'" *ngIf="token.role==='[ROLE_AGENT]'" type="button"
                                                                (click)="openchat(ticket.ticketId)"
                                                                class="btn btn-secondary float-end">Open Ticket</button>
                                                        </div>
                                                    </div>
                                                    <div class="mt-8  text-center">
                                                        <pagination-controls
                                                            (pageChange)="p = $event"></pagination-controls>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<div class="col-lg-4">
                                        <div class="card mt-3">
                                            <div class="card-header">
                                                <h5 class="text-center">Recent Activities</h5>
                                            </div>
                                            <div class="card-body">
                                                <h6 class="mt-2"><b>Kwazi closed "How to reset password?"</b><br>
                                                    <h6>06 April 2023,19:16</h6>
                                                </h6>
                                                <hr>
                                                <h6><b>John closed "How to reset password?"</b><br>
                                                    <h6>06 April 2023,19:16</h6>
                                                </h6>
                                                <hr>
                                                <h6><b>John closed "How to reset password?"</b><br>
                                                    <h6>06 April 2023,19:16</h6>
                                                </h6>
                                                <hr>
                                                <h6><b>John closed "How to reset password?"</b><br>
                                                    <h6>06 April 2023,19:16</h6>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModal" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-center" id="myModal">Open New Ticket</h5>
                <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form [formGroup]="openTicketForm" (ngSubmit)="openTicket()">
                <div class="modal-body">
                    <div class="form-floating col-12 mb-2">
                        <input type="text" class="form-control" name="category" id="category" required
                            formControlName="category"
                            [class.is-invalid]="f['category'].invalid && f['category'].touched">
                        <label style="color: #6c757d;" for="category">Category(eg. Software issue, hardware etc...)</label>
                        <div class="text-center" *ngIf="f['category'].invalid && f['category'].touched">
                            <p style="color: crimson;" class="small mt-1" *ngIf="f['category'].errors?.['required']">
                                Category is
                                required!</p>
                        </div>
                    </div>
                    <div class="form-floating col-12">
                        <textarea class="form-control" name="category" id="category" required
                            formControlName="description"
                            [class.is-invalid]="f['description'].invalid && f['description'].touched"
                            style="height: 100px;"></textarea>
                        <label style="color: #6c757d;" for="description">Description</label>
                        <div class="text-center" *ngIf="f['description'].invalid && f['description'].touched">
                            <p style="color: crimson;" class="small mt-1" *ngIf="f['description'].errors?.['required']">
                                Description is
                                required!</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" [disabled]="openTicketForm.invalid" class="btn btn-primary">Open
                        Ticket</button>
                </div>
            </form>
        </div>
    </div>
</div>