import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ServiceDeskFrontend';

  constructor(public _route:Router){}

  isMatching():boolean{
    const desiredUrl='/#'

    const currentUrl = this._route.url;

    return currentUrl.startsWith(desiredUrl);
  }
}
