import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminTickets, Agent } from 'src/app/util/models/models';
import { TicketService } from 'src/app/util/services/ticket.service';
import { ToastService } from 'src/app/util/toast.service';
import { getStatusColor, getPriorityColor } from 'src/app/util/status-prority-colors';
import jwt_decode from 'jwt-decode';
import { Subject } from 'rxjs';
import { AgentCustomerService } from 'src/app/util/services/agent-customer.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-admin-tickets',
  templateUrl: './admin-tickets.component.html',
  styleUrls: ['./admin-tickets.component.css']
})
export class AdminTicketsComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  datatableElement?: DataTableDirective;

  tickets!: AdminTickets[];
  agents!: Agent[];

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  authToken!: any;
  companyId!: any;

  getStatusColor = getStatusColor;
  getPriorityColor = getPriorityColor;

  constructor(private spinner: NgxSpinnerService, private agentService: AgentCustomerService, private ticket: TicketService, private toast: ToastService) { }

  reassignForm = new FormGroup({
    agent: new FormControl('', Validators.required)
  })
  public get f() { return this.reassignForm.controls; }

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.companyId = jwt_decode(this.authToken);

    this.getTickets(this.companyId.companyId);
    this.getAgents(this.companyId.companyId);
    this.ticket.RefreshRequired.subscribe(res => { this.getTickets(this.companyId.companyId); })

    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      processing: true
    };
  }

  public reassignAgent(ticketId: any): void {
    this.spinner.show();
    let agentId = this.reassignForm.get('agent')?.value;
    let message;

    this.ticket.reassignTicket(ticketId, agentId).subscribe(
      {
        next: (res: any) => {
          if (res) { this.spinner.hide() }
          message = res;
          this.toast.showSuccess(message.message);
          this.reassignForm.reset();
        }, error: (err: any) => {
          if (err) { this.spinner.hide() }
          this.toast.showError(err);
        }
      }
    )
  }

  private getAgents(companyId: any): void {
    this.agentService.getAgents(companyId).subscribe(
      (agents: Agent[]) => {
        this.agents = agents;
      }, (error) => {
        this.toast.showError(error);
      }
    )
  }

  private getTickets(companyId: any): void {
    if (this.datatableElement) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.fetchTickets(companyId);
      });
    } else {
      this.fetchTickets(companyId);
    }
  }

  private fetchTickets(companyId: any): void {
    this.spinner.show();
    this.ticket.adminTickets(companyId).subscribe({
      next: (tickets: AdminTickets[]) => {
        this.spinner.hide();
        this.tickets = tickets;
        this.dtTrigger.next(null);
      },
      error: (err: any) => {
        this.toast.showError(err);
        this.spinner.hide();
      }
    });
  }

  ngOnDestroy(): void { this.dtTrigger.unsubscribe(); }
}