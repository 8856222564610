<main id="main" class="main">
    <div class="pagetitle">
        <h1>Subscriptions</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">subscriptions</li>
            </ol>
        </nav>
    </div>
    <section class="section">

        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card info-card admin-card">
                            <div class="card-body">
                                <div class="container mt-4">

                                    <div class="row gy-4">
                                        <div class="col-lg-12">
                                            <nav id="orders-table-tab"
                                                class="orders-table-tab app-nav-tabs nav shadow-sm flex-column flex-sm-row mb-4">
                                                <a class="flex-sm-fill text-sm-center nav-link active"
                                                    id="subscriptions-tab" data-bs-toggle="tab" href="#subscriptions"
                                                    role="tab" aria-controls="subscriptions" aria-selected="true">
                                                    SUBSCRIPTIONS</a>
                                                <a class="flex-sm-fill text-sm-center nav-link" id="packages-tab"
                                                    data-bs-toggle="tab" href="#packages" role="tab"
                                                    aria-controls="packages" aria-selected="false">PACKAGES</a>
                                            </nav>

                                            <div class="tab-content" id="orders-table-tab-content">
                                                <div class="tab-pane fade show active" id="subscriptions"
                                                    role="tabpanel" aria-labelledby="subscriptions">
                                                    <div class="app-card">
                                                        <div class="app-card-body p-2">
                                                            <h5 class="card-title">List of Users</h5>
                                                            <table datatable class="table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Full Name</th>
                                                                        <th scope="col">Email</th>
                                                                        <th scope="col">Department</th>
                                                                        <th scope="col">Position</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade show" id="packages" role="tabpanel"
                                                    aria-labelledby="packages">
                                                    <div class="app-card">
                                                        <div class="app-card-body p-2">
                                                            <div class="row">
                                                                <div class="col-lg-3 col-md-6" data-aos="zoom-im"
                                                                    data-aos-delay="100">
                                                                    <div class="box">
                                                                        <h3>Trial</h3>
                                                                        <h4><sup>R</sup>0<span> / month</span></h4>
                                                                        <ul>
                                                                            <li>Limited to: 2 users</li>
                                                                            <li>Others</li>
                                                                        </ul>
                                                                        <div class="btn-wrap">
                                                                            <a href="#" class="btn-buy">Subscribe</a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-3 col-md-6 mt-4 mt-md-0"
                                                                    data-aos="zoom-in" data-aos-delay="100">
                                                                    <div class="box">
                                                                        <h3>basic</h3>
                                                                        <h4><sup>R</sup>100<span> / month</span></h4>
                                                                        <ul>
                                                                            <li>Limited to: 10 users</li>
                                                                            <li>Others</li>
                                                                        </ul>
                                                                        <div class="btn-wrap">
                                                                            <a href="#" class="btn-buy">Subscribe</a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-3 col-md-6 mt-4 mt-lg-0"
                                                                    data-aos="zoom-in" data-aos-delay="100">
                                                                    <div class="box">
                                                                        <h3>Business</h3>
                                                                        <h4><sup>R</sup>178<span> / month</span></h4>
                                                                        <ul>
                                                                            <li>Limited to: 50 users</li>
                                                                            <li>Others</li>
                                                                        </ul>
                                                                        <div class="btn-wrap">
                                                                            <a href="#" class="btn-buy">Subscribe</a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-3 col-md-6 mt-4 mt-lg-0"
                                                                    data-aos="zoom-in" data-aos-delay="100">
                                                                    <div class="box">
                                                                        <h3>Enterprise</h3>
                                                                        <h4><sup>R</sup>200<span> / month</span></h4>
                                                                        <ul>
                                                                            <li>Limited to: 100+ users</li>
                                                                            <li>Others</li>
                                                                        </ul>
                                                                        <div class="btn-wrap">
                                                                            <a href="#" class="btn-buy">Subscribe</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>