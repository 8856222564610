import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MainpageComponent } from './pages/mainpage/mainpage.component';
import { CompanyCreateAccountComponent } from './pages/company-create-account/company-create-account.component';
import { CompanyLoginComponent } from './pages/company-login/company-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CompanyDashboardComponent } from './pages/company-dashboard/company-dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { DataTablesModule } from 'angular-datatables';
import { RegisterAdminComponent } from './pages/register-admin/register-admin.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { CompanySettingsComponent } from './pages/company-settings/company-settings.component';
import { RegisterUsersComponent } from './pages/register-users/register-users.component';
import { HttpRequestInterceptor } from './util/interceptor';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { RegisterComponent } from './components/register/register.component';
import { SharedService } from './util/services/shared.service';
import { TicketsComponent } from './pages/tickets/tickets.component';
import { OpenChatComponent } from './pages/open-chat/open-chat.component';
import { ChatComponent } from './components/chat/chat.component';
import { LoginComponent } from './pages/login/login.component';
import { AgentDashboardComponent } from './pages/agent-dashboard/agent-dashboard.component';
import { EnduserDashboardComponent } from './pages/enduser-dashboard/enduser-dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import * as echarts from 'echarts';
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts';
import { ErrorHandlerService } from './util/error-handler.service';
import { AdminTicketsComponent } from './pages/admin-tickets/admin-tickets.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    MainpageComponent,
    CompanyCreateAccountComponent,
    CompanyLoginComponent,
    CompanyDashboardComponent,
    DashboardComponent,
    RegisterAdminComponent,
    SubscriptionsComponent,
    SettingsComponent,
    CompanySettingsComponent,
    RegisterUsersComponent,
    AdminDashboardComponent,
    RegisterComponent,
    TicketsComponent,
    OpenChatComponent,
    ChatComponent,
    LoginComponent,
    AgentDashboardComponent,
    EnduserDashboardComponent,
    AdminTicketsComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DataTablesModule,
    NgxPaginationModule,
    CanvasJSAngularChartsModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ],
  providers: [ {provide:ErrorHandler,useClass:ErrorHandlerService},SharedService, { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
