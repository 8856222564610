import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { TicketResponse } from 'src/app/util/models/models';
import jwt_decode from 'jwt-decode';
import { TicketService } from 'src/app/util/services/ticket.service';
import { Error } from 'src/app/util/error';
import { getStatusColor, getPriorityColor } from 'src/app/util/status-prority-colors';

@Component({
  selector: 'app-enduser-dashboard',
  templateUrl: './enduser-dashboard.component.html',
  styleUrls: ['./enduser-dashboard.component.css']
})
export class EnduserDashboardComponent implements OnInit, OnDestroy {

  tickets: TicketResponse[] = [];
  getStatusColor = getStatusColor;
  getPriorityColor = getPriorityColor;

  authToken!: any;
  userId!: any;
  closedTickets:number=0;
  openTickets:number=0;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private spinner: NgxSpinnerService, private service: TicketService) { }


  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.userId = jwt_decode(this.authToken);

    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 5,
      processing: true
    };

    this.gateTickets(this.userId.accountId);
  }

  private gateTickets(userId: any): void {
    this.spinner.show()
    this.service.getUserTickets(userId).subscribe(res => {
      if (res) { this.spinner.hide() }
      this.tickets = res;
      for(let i of this.tickets){
        if(i.status==="Open")
          this.openTickets=this.openTickets+1;
        if(i.status=="Closed")
          this.closedTickets+=1;
      }
      this.dtTrigger.next(null);
    }, error => {
      if (error) { this.spinner.hide() }
      Error.error(error);
    })
  }

  ngOnDestroy(): void { this.dtTrigger.unsubscribe(); }
}