<main id="main" class="main">
    <div class="pagetitle">
        <h1>Reports</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">reports</li>
            </ol>
        </nav>
    </div>
    <section class="section">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-4 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">New Tickets &nbsp;<i title="Report containing new tickets reported in a day" class="bi bi-info-circle"></i></h5>
                                <div echarts [options]="chartOption" class="demo-chart"></div>

                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Resolved Tickets &nbsp;<i title="Report containing tickets resolved in a day" class="bi bi-info-circle"></i></h5>
                                <div echarts [options]="resolvedChartOptions" class="demo-chart"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Users &nbsp;<i title="Chart on users" class="bi bi-info-circle"></i></h5>
                                <div echarts [options]="usersChartOptions" class="demo-chart"></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</main>