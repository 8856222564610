<div class="content">
    <div class="container">
        <div class="row">
            <div class="col-md-6 order-md-2">
                <img src="../../../assets/img/managed-it-services-2.png" alt="Image" class="img-fluid">
            </div>
            <div class="col-md-6 contents">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="mb-4">
                            <h3>Sign In to <strong>Service Desk</strong>
                                <img src="../../../assets/img/service-desk.png" alt="Image" class="img-fluid">
                            </h3>
                            <p class="mb-4"></p>
                        </div>
                        <form [formGroup]="signinForm" (ngSubmit)="login()">

                            <div class="form-group">
                                <input type="email" class="form-control" id="email" formControlName="email"
                                    placeholder="Enter Company Email"
                                    [class.is-invalid]="f['email'].invalid && f['email'].touched" required>
                                <div class="text-center" *ngIf="f['email'].invalid && f['email'].touched">
                                    <p style="color: crimson;" class="small mt-1"
                                        *ngIf="f['email'].errors?.['required']">Company email is
                                        required!</p>
                                </div>
                            </div>

                            <div class="form-group">
                                <input type="password" class="form-control" id="password" formControlName="password"
                                    placeholder="Enter Password" #passwordInput
                                    [class.is-invalid]="f['password'].invalid && f['password'].touched" required>
                                <div class="text-center" *ngIf="f['password'].invalid && f['password'].touched">
                                    <p style="color: crimson;" class="small mt-1"
                                        *ngIf="f['password'].errors?.['required']">Password is
                                        required!</p>
                                </div>
                                <div class="form-text">
                                    <input type="checkbox" id="showPassword"> Show Password
                                </div>
                            </div>

                            <button [disabled]="signinForm.invalid" type="submit" class="btn btn-primary">Login</button>

                            <div class="text-center mt-3">
                                <p>Don't have an account? <a routerLink="/create-account"
                                        class="small text-primary">Register</a>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>