import Swal from "sweetalert2";

export class Error {
  static error(error: any) {
    Swal.fire({
      timer: 2000,
      title: 'Error',
      icon: 'error',
      text: error.error.error,
      position: 'center',
      showConfirmButton: false
    })
  }
}