<form [formGroup]="registerUserForm" (ngSubmit)="addUser()">
    <div class="row gy-2 gx-md-3">

        <div class="form-floating col-12">
            <select type="text" class="form-control" name="role" id="role" required formControlName="role"
                [class.is-invalid]="f['role'].invalid && f['role'].touched">
                <option value="User">End User</option>
                <option value="Agent">Agent</option>
            </select>
            <label style="color: #6c757d;" for="position">User Role</label>
            <div class="text-center" *ngIf="f['role'].invalid && f['role'].touched">
                <p style="color: crimson;" class="small mt-1" *ngIf="f['role'].errors?.['required']">
                    Role is
                    required!</p>
            </div>
        </div>

        <div class="form-floating col-12">
            <input type="text" class="form-control" name="position" id="position" required formControlName="position"
                [class.is-invalid]="f['position'].invalid && f['position'].touched">
            <label style="color: #6c757d;" for="position">Position</label>
            <div class="text-center" *ngIf="f['position'].invalid && f['position'].touched">
                <p style="color: crimson;" class="small mt-1" *ngIf="f['position'].errors?.['required']">
                    Position is
                    required!</p>
            </div>
        </div>

        <div class="form-floating col-md-6">
            <input type="text" name="name" class="form-control" id="name" formControlName="fullName"
                [class.is-invalid]="f['fullName'].invalid && f['fullName'].touched" required>
            <label style="color: #6c757d;" for="name">First
                Name</label>
            <div class="text-center" *ngIf="f['fullName'].invalid && f['fullName'].touched">
                <p style="color: crimson;" class="small mt-1" *ngIf="f['fullName'].errors?.['required']">
                    First name is
                    required!</p>
            </div>
        </div>

        <div class="form-floating col-md-6">
            <input type="text" name="lastname" class="form-control" id="lastname" required formControlName="lastName"
                [class.is-invalid]="f['lastName'].invalid && f['lastName'].touched">
            <label style="color: #6c757d;" for="lastname">Last Name</label>
            <div class="text-center" *ngIf="f['lastName'].invalid && f['lastName'].touched">
                <p style="color: crimson;" class="small mt-1" *ngIf="f['lastName'].errors?.['required']">
                    Lastname is
                    required!</p>
            </div>
        </div>

        <div class="form-floating col-md-6">
            <input type="text" name="contact" class="form-control" id="contact" required formControlName="contactNumber"
                [class.is-invalid]="f['contactNumber'].invalid && f['contactNumber'].touched">
            <label style="color: #6c757d;" for="contact">Contact
                Number</label>
            <div class="text-center" *ngIf="f['contactNumber'].invalid && f['contactNumber'].touched">
                <p style="color: crimson;" class="small mt-1" *ngIf="f['contactNumber'].errors?.['required']">
                    Contact
                    number is
                    required!</p>
            </div>
        </div>

        <div class="form-floating col-md-6">
            <input type="email" name="email" class="form-control" id="email" required formControlName="email"
                [class.is-invalid]="f['email'].invalid && f['email'].touched">
            <label style="color: #6c757d;" for="email">Email</label>
            <div class="text-center" *ngIf="f['email'].invalid && f['email'].touched">
                <p style="color: crimson;" class="small mt-1" *ngIf="f['email'].errors?.['required']">
                    Email is
                    required!</p>
            </div>
        </div>

        <div class="text-end col-12">
            <button type="submit" [disabled]="registerUserForm.invalid">Submit</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
    </div>
</form>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>