import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UpdateTicket, Agent, RMessage, TicketRespond, User } from 'src/app/util/models/models';
import { TicketService } from 'src/app/util/services/ticket.service';
import jwt_decode from 'jwt-decode';
import { Client, IStompSocket, Message, Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client'
import { Error } from 'src/app/util/error';
import { Observable, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AgentCustomerService } from 'src/app/util/services/agent-customer.service';
import { httpOptions } from 'src/app/util/constants/constants';



@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  @Input() ticketId: any;


  constructor(private service: TicketService, private spinner: NgxSpinnerService, private httpClient: HttpClient,
    private __service: AgentCustomerService) { }

  updateticket = new UpdateTicket();
  isSecondSelectDisabled: boolean = true;
  messages: RMessage[] = [];
  newMessage: string = '';
  user = new User();

  ticket = new TicketRespond();
  authToken!: any;
  accountId!: any;
  assignedTo: String = '';
  name?:any;

  messageList?: Observable<Array<RMessage>>;

  msg!: string;

  stompClient: Client = new Client;

  ngOnInit(): void {
    this.getTicket(this.ticketId)
    this.authToken = sessionStorage.getItem('auth-user');
    this.accountId = jwt_decode(this.authToken);
    this.connect()
    this.getUser(this.accountId.accountId, (name: string) => {
    
    });
  }

  
  agents: Agent[] = [];

  updateTicketForm = new FormGroup({
    status: new FormControl('', Validators.required),
    ticketId: new FormControl('', Validators.required),
    updateMessage: new FormControl(''),
    escalatedToAgentId: new FormControl('')
  })

  public get f() { return this.updateTicketForm.controls; }

  updateTicket() { }

  private connect(): void {
    this.spinner.show();
    this.loadChat()
    this.stompClient.webSocketFactory = (): IStompSocket => {
      return <IStompSocket>(<unknown>new SockJS('/chat'));
    };

    this.stompClient.onConnect = (frame) => {
      this.spinner.hide();
      this.stompClient.subscribe("/topic/messages/" + this.ticketId, (message: Message) => {
        const receivedMsg = JSON.parse(message.body) as RMessage;
        this.messageList = this.messageList?.pipe(map((d: RMessage[]) => [...d, receivedMsg]))
        this.messages.push(receivedMsg);
      });
    }
    this.stompClient.activate();
  }

  private getTicket(ticketId: any): void {
    this.spinner.show();
    this.service.getTicket(ticketId).subscribe({
      next: (res: any) => {
        this.spinner.hide()
        this.ticket = Object.assign(this.ticket, res);
      }, error: (error: any) => {
        if (error) { this.spinner.hide(); }
        Error.error(error)
      }
    });
  }

  public sendMessage() {
    const message = {
      sender: this.accountId.accountId,
      content: this.newMessage
    }

    this.stompClient?.publish({
      destination: '/app/chat/' + this.ticketId,
      body: JSON.stringify(message)
    });

    this.newMessage = "";
  }

  public loadChat(): void {
    this.messageList = this.httpClient.get<Array<RMessage>>("/get-messages/" + this.ticketId,httpOptions);

    this.messageList.subscribe(d => {
      let mesg: Array<RMessage> = d;
      
      mesg.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
      this.messageList = of(mesg)
    })
  }

  private getUser(accountId: any, callback: (name: string) => void): void {
    this.__service.getUser(accountId).subscribe({
      next: (res) => {
        const name = res.fullName;
        this.name = name;
        callback(name);
      }
    });
  }
  


}
