import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { TicketService } from 'src/app/util/services/ticket.service';
import { TicketResponse } from 'src/app/util/models/models';
import { Error } from 'src/app/util/error';
import { getStatusColor, getPriorityColor } from 'src/app/util/status-prority-colors';

@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './agent-dashboard.component.html',
  styleUrls: ['./agent-dashboard.component.css']
})
export class AgentDashboardComponent implements OnInit {

  tickets: TicketResponse[]=[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  authToken!:any;
  token!:any;
  totalTickets:any;
  closedTickets:number=0;
  openTickets:number=0;
  escalatedTickets:number=0;
  getStatusColor = getStatusColor;
  getPriorityColor = getPriorityColor;

  constructor(private spinner:NgxSpinnerService,private service:TicketService){}

  ngOnInit(): void {
     this.spinner.hide();
     this.authToken = sessionStorage.getItem('auth-user');
    this.token = jwt_decode(this.authToken);

    this.gateTickets(this.token.accountId);

    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 5,
      processing: true
    };
  }

  private gateTickets(userId: any): void {
    this.spinner.show()
    this.service.getAgentTickets(userId).subscribe(res => {
      if (res) { this.spinner.hide() }
      this.tickets = res;
      this.totalTickets=this.tickets.length
      this.dtTrigger.next(null);
      for(let i of this.tickets){
        if(i.status==="Open")
          this.openTickets=this.openTickets+1;
        if(i.status=="Closed")
          this.closedTickets+=1;
        if(i.status=="Escalate")
          this.escalatedTickets+=1;
      }
    }, error => {
      if (error) { this.spinner.hide() }
      Error.error(error);
    })
  }

  ngOnDestroy(): void { this.dtTrigger.unsubscribe(); }

}
