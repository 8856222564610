import { FormGroup } from "@angular/forms";

export class Validation {
    static matchpassword(password:any,repassword:any){
        return(formGroup: FormGroup)=>{
          const passcontrol=formGroup.controls[password];
          const repasscontrol=formGroup.controls[repassword];
          if(repasscontrol.errors && !repasscontrol.errors['matchpassword']){ return;}
          if(passcontrol.value !== repasscontrol.value){repasscontrol.setErrors({matchpassword:true});}
          else{ repasscontrol.setErrors(null);}
        };
    }
}