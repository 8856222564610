<header id="header" class="header fixed-top d-flex align-items-center">
    <div class="d-flex align-items-center justify-content-between"> <a style="text-decoration: none;"
            routerLink="/dashboard" class="logo d-flex align-items-center"> <span class="d-none d-lg-block"></span> <img src="../../../assets/img/SDT New logo-01 (11).png" style="height: 200px;" alt=""></a> <i (click)="sidebarToggle()"
            class="bi bi-list toggle-sidebar-btn"></i></div>
    <nav class="header-nav ms-auto">
        <ul class="d-flex align-items-center">
            
            <li class="nav-item dropdown pe-3">
                <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown"><span
                        class="d-none d-md-block dropdown-toggle ps-2">{{name.name=="Null" ? name.sub:
                        name.name}}</span> </a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                    <li class="dropdown-header">
                        <h6>Name: {{name.name}}</h6>
                        <!--<h6>Company: {{name.sub}}</h6>-->
                    </li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li> <a class="dropdown-item d-flex align-items-center" routerLink="/settings"> <i
                                class="bi bi-gear"></i> <span>Settings</span> </a></li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li> <a style="cursor: pointer;" class="dropdown-item d-flex align-items-center" (click)="logout()">
                            <i class="bi bi-box-arrow-left"></i> <span>Logout</span> </a></li>
                </ul>
            </li>
        </ul>
    </nav>
</header>
