import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegisterUser, RegisterUsersResponse, Users } from 'src/app/util/models/models';
import { SharedService } from 'src/app/util/services/shared.service';
import jwt_decode from 'jwt-decode';
import { UsersService } from 'src/app/util/services/users.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastService } from 'src/app/util/toast.service';

@Component({
  selector: 'app-register-users',
  templateUrl: './register-users.component.html',
  styleUrls: ['./register-users.component.css']
})
export class RegisterUsersComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, { static: false })
  datatableElement?: DataTableDirective;

  constructor(private toast: ToastService, private router: Router, private _service: UsersService, private sharedService: SharedService, private spinner: NgxSpinnerService) { }

  users: RegisterUser[] = [];
  authToken!: any;
  companyId!: any;
  response = new RegisterUsersResponse();
  registeredUsers: Users[] = [];
  message!: any;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.companyId = jwt_decode(this.authToken);

    this.sharedService.getArray().subscribe((data) => {
      this.users = data;
    })

    this.getUsers(this.companyId.companyId);

    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      processing: true
    };
  }

  private getUsers(companyId: any): void {
    if (this.datatableElement) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.fetchUsers(companyId);
      });
    } else {
      this.fetchUsers(companyId)
    }
  }

  private fetchUsers(companyId: any): void {
    this.spinner.show();
    this._service.getusers(companyId).subscribe({
      next: (users: Users[]) => {
        this.spinner.hide();
        this.registeredUsers = users;
        this.dtTrigger.next(null);
      }, error: (error: any) => {
        if (error) { this.spinner.hide() }
        this.toast.showError(error)
      }
    });
  }

  public registerUsers(): void {
    this.spinner.show();
    this._service.registerusers(this.users, this.companyId.companyId).subscribe({
      next:(res:any)=>{
        if (res) { this.spinner.hide(); }
        this.response = res;
        let text="You have registered " + this.response.successful + " successfully and " + this.response.failed + " failed,\n"
        + this.response.agents + "Agents\n"
        + this.response.users + " Users,\n" + " Failed Account" + this.response.failedAccounts;
        this.toast.showSuccess(text);
        location.reload();
      },
      error:(error:any)=>{
        this.toast.showError(error)
      }
    })  
  }

  public resetPassword(accountId: any, role: any): void {
    this.spinner.show();
    this._service.resetpassword(accountId, role).subscribe({
      next: (res: any) => {
        if (res) { this.spinner.hide() }
        this.message = res;
        this.toast.showSuccess(this.message.message);
      }, error: (error: any) => {
        if (error) { this.spinner.hide() }
        this.toast.showError(error);
      }
    })
  }

  ngOnDestroy(): void { this.dtTrigger.unsubscribe(); }
}