import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Login, SignUpCompany } from '../models/models';
import { Constants, httpOptions } from '../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class CompAuthService {

    constructor(private _httpClient: HttpClient) { }

    public login(login: Login): Observable<any> {
        return this._httpClient.post(Constants.GATEWAY_URL + "auth/login", login, httpOptions);
    }

    public register(signup: SignUpCompany): Observable<any> {
        return this._httpClient.post(Constants.GATEWAY_URL + "auth/signup", signup, httpOptions);
    }
}