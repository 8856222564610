import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Agent, Login } from '../models/models';
import { Constants, httpOptions, httpOptionsWithAuth } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AgentCustomerService {
  constructor(private _httpClient: HttpClient) { }

  public login(login: Login): Observable<any> {
    return this._httpClient.post(Constants.GATEWAY_URL + "auth/login", login, httpOptions);
  }

  public getUser(accountId: any): Observable<any> {
    return this._httpClient.get(Constants.GATEWAY_URL + `users/get-user/${accountId}`, httpOptionsWithAuth);
  }

  public getAgents(companyId:any):Observable<Agent[]>{
    return this._httpClient.get<Agent[]>(Constants.GATEWAY_URL+`users/get-agents/${companyId}`,httpOptionsWithAuth);
  }
}
