export class SignUpCompany {
    companyName!: String;
    companyEmail!: String;
    contactNumber!: String;
    password!: String;
}

export class Login {
    email!: String;
    password!: String;
}

export class RegisterAdmin {
    fullName!: String;
    email!: String
    contactNumber!: String;
    lastName!: String;
    position!: String;
}

export class AdminResponse {
    fullName!: String;
    email!: String
    position!: String;
}

export class RegisterUser {
    fullName!: String;
    email!: String
    contactNumber!: String;
    lastName!: String;
    position!: String;
    role!: String;
}

export class RegisterUsersResponse {
    successful!: any;
    failed!: any;
    users!: any
    agents!: any
    failedAccounts!: String[];
}

export class Users {
    accountId!: String;
    fullName!: String;
    email!: String;
    position!: String;
    role!: String;
}

export class UpdatePassword {
    accountId!: any;
    password!: any;
    role!:any;
}

export class UpdateTicket {
    status!: String;
    ticketId!: any;
    updateMessage!: String;
    escalatedToAgentId!: any;
}

export class Agent {
    accountId!: any;
    fullName!: String;
    status!: String;
    email!: String;
    isOnLeave!: String;
}

export class TicketResponse {
    category!: String;
    description!: String;
    ticketId!: String;
    status!: String;
    createdAt!: Date;
    priority!: String;
}

export class RequestService{
    category!:String;
    description!:String;
    customerUserId!:String;
}

export class TicketRespond {
    category!: String;
    description!: String;
    ticketId!: String;
    status!: String;
    createdAt!: any;
    priority!: string;
    assignedTo!:string;
    updateAt!:any;
}

export class Data{
    description!:String
    assignedTo!:String
    accountId!:String
}

export class RMessage{
    sender!:String;
    content!:String;
    timestamp!:Date;
    ticketId!:String;
}

export class User{
    accountId?:any
    fullName?:String
    email?:any
    position?:any
    role?:any
}

export class AdminTickets{
    category?:any;
    loggedBy?:any;
    ticketId?:any;
    status?:any;
    createdAt?:any;
    priority?:any;
    assignedTo?:any;
}