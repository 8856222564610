<header id="header" class="fixed-top">
    <div class="container d-flex align-items-center justify-content-between">
        
        <h1 class="logo"><a href="/"><img src="../../../assets/img/service-desk.png" alt="" 
            class="img-fluid">Service Desk</a></h1>
        
        <nav id="navbar" class="navbar">
            <ul>
                <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
                <li><a class="nav-link scrollto" href="#about">About</a></li>
                <li><a class="nav-link scrollto" href="#pricing">Pricing</a></li>
                <li><a class="nav-link scrollto" href="#contact">Contact us</a></li>
                <li><a class="nav-link scrollto" href="comp-login">Login</a></li>
                <li><a class="createaccount scrollto" href="create-account">Create an account</a></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->

    </div>
</header>


<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center">
    <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 text-center">
          <h1>Ready to start with Service Desk?</h1>
        </div>
      </div>
      <div class="text-center">
        <a href="#about" class="btn-get-started scrollto">Get Started</a>
      </div>

      <div class="row icon-boxes">
        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-gear"></i></div>
            <h4 class="title"><a href="">key Service</a></h4>
            <p class="description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus molestias veritatis omnis assumenda est quisquam? Fuga cumque, est laborum molestiae odio at, alias, eius nobis deserunt enim laboriosam quia numquam.
            </p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon-box">
              <div class="icon"><i class="bi bi-gear"></i></div>
              <h4 class="title"><a href="">key Service</a></h4>
              <p class="description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus molestias veritatis omnis assumenda est quisquam? Fuga cumque, est laborum molestiae odio at, alias, eius nobis deserunt enim laboriosam quia numquam.
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon-box">
              <div class="icon"><i class="bi bi-gear"></i></div>
              <h4 class="title"><a href="">key Service</a></h4>
              <p class="description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus molestias veritatis omnis assumenda est quisquam? Fuga cumque, est laborum molestiae odio at, alias, eius nobis deserunt enim laboriosam quia numquam.
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon-box">
              <div class="icon"><i class="bi bi-gear"></i></div>
              <h4 class="title"><a href="">key Service</a></h4>
              <p class="description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus molestias veritatis omnis assumenda est quisquam? Fuga cumque, est laborum molestiae odio at, alias, eius nobis deserunt enim laboriosam quia numquam.
              </p>
            </div>
          </div>
      </div>
    </div>
  </section><!-- End Hero -->

  <main >

     <!-- ======= About Section ======= -->
     <section id="about" class="about">
        <div class="container" data-aos="fade-up">
  
          <div class="section-title">
            <h2>About Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro, maiores magnam mollitia labore quisquam temporibus animi aut dolore perferendis. Error, ut reiciendis. Velit minima magnam nisi tempora nam ducimus aut?</p>
          </div>
  
          <div class="row content">
            <div class="col-lg-6">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa nihil perspiciatis, esse minus est libero quae molestiae, aut doloribus consequatur, quam earum error ipsum! Labore praesentium temporibus doloremque culpa reiciendis!
              </p>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <p>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
  
        </div>
      </section><!-- End About Section -->

      <!-- ======= Pricing Section ======= -->
    <section id="pricing" class="pricing">
        <div class="container" data-aos="fade-up">
  
          <div class="section-title">
            <h2>Pricing</h2>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus maiores tenetur quia obcaecati blanditiis, nulla ipsa unde, odit debitis quidem est reiciendis voluptas molestias aliquam. Voluptas dignissimos odio esse nostrum?</p>
           </div>
  
          <div class="row">
  
            <div class="col-lg-3 col-md-6" data-aos="zoom-im" data-aos-delay="100">
              <div class="box">
                <h3>Trial</h3>
                <h4><sup>R</sup>0<span> / month</span></h4>
                <ul>
                    <li>Limited to: 2 users</li>
                    <li>Others</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Subscribe</a>
                </div>
              </div>
            </div>
  
            <div class="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="100">
              <div class="box">
                <h3>basic</h3>
                <h4><sup>R</sup>100<span> / month</span></h4>
                <ul>
                    <li>Limited to: 10 users</li>
                    <li>Others</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Subscribe</a>
                </div>
              </div>
            </div>
  
            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="100">
              <div class="box">
                <h3>Business</h3>
                <h4><sup>R</sup>178<span> / month</span></h4>
                <ul>
                  <li>Limited to: 50 users</li>
                  <li>Others</li>
                </ul>
                <div class="btn-wrap">
                  <a href="#" class="btn-buy">Subscribe</a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="100">
                <div class="box">
                  <h3>Enterprise</h3>
                  <h4><sup>R</sup>200<span> / month</span></h4>
                  <ul>
                    <li>Limited to: 100+ users</li>
                  <li>Others</li>
                  </ul>
                  <div class="btn-wrap">
                    <a href="#" class="btn-buy">Subscribe</a>
                  </div>
                </div>
              </div>
  
          </div>
  
        </div>
      </section><!-- End Pricing Section -->

      <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
  
          <div class="section-title">
            <h2>Contact</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, inventore aut reiciendis vitae obcaecati odio molestias asperiores consectetur esse, quaerat tenetur, id architecto. Repellat sed eligendi id veniam sequi corporis.</p>
          </div>
  
          <div class="row mt-5">
  
            <div class="col-lg-4">
              <div class="info">
                <div class="address">
                  <i class="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>PRetafas, sadasd ds</p>
                </div>
  
                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>info@servicedesk.com</p>
                </div>
  
                <div class="phone">
                  <i class="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>012 435 3444</p>
                </div>
  
              </div>
  
            </div>
  
            <div class="col-lg-8 mt-5 mt-lg-0">
  
              <form method="post" role="form" class="form">
                <div class="row gy-2 gx-md-3">
                  <div class="col-md-6 form-group">
                    <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
                  </div>
                  <div class="col-md-6 form-group">
                    <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
                  </div>
                  <div class="form-group col-12">
                    <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
                  </div>
                  <div class="form-group col-12">
                    <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                  </div>
                  <div class="text-center col-12"><button type="submit">Send Message</button></div>
                </div>
              </form>
  
            </div>
  
          </div>
  
        </div>
      </section><!-- End Contact Section -->

  </main>

<!-- ======= Footer ======= -->
<footer id="footer">

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6 footer-contact">
            <h3 style="color: black;">Service Desk</h3>
            <p>
              230 Jorisen Street <br>
              Sunnyside Pretoria, 0002<br>
              South Africa <br><br>
              <strong>Phone:</strong> 015 962 3022<br>
              <strong>Email:</strong> info@servicedesk.com<br>
            </p>
          </div>

          <div class="col-lg-4 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-6 footer-newsletter">
            <h4>Subscribe for specials</h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde eius impedit, maiores officiis assumenda, repellat placeat, ad ut laborum reprehenderit ea magni aut consequuntur mollitia sit cum doloremque saepe deserunt.</p>
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="container d-md-flex py-4">

      <div class="me-md-auto text-center text-md-start">
        <div class="copyright">
          &copy; Copyright <strong><span>Service Desk</span></strong>. All Rights Reserved
        </div>
      </div>
      <div class="social-links text-center text-md-right pt-3 pt-md-0">
        <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="#" class="linkedin"><i class="bx bxl-youtube"></i></a>
        <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
      </div>
    </div>
  </footer><!-- End Footer -->