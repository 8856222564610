import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RequestService, TicketResponse } from 'src/app/util/models/models';
import jwt_decode from 'jwt-decode';
import { TicketService } from 'src/app/util/services/ticket.service';
import { Error } from 'src/app/util/error';
import { Successful } from 'src/app/util/successful';
import { getStatusColor, getPriorityColor } from 'src/app/util/status-prority-colors';
import { Client, IStompSocket, Message } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {

  requestService = new RequestService();

  openChat: boolean = false;
  tickets: TicketResponse[] = [];
  filterdTickets: TicketResponse[] = [];
  ticketId: any;
  message!: any;

  audio?: HTMLAudioElement;

  authToken!: any;
  token!: any;

  getStatusColor = getStatusColor;
  getPriorityColor = getPriorityColor;

  stompClient: Client = new Client;

  p: any = 1;
  itemsPerPage: any = 5;

  constructor(private router: Router, private spinner: NgxSpinnerService, private service: TicketService) { }

  openTicketForm = new FormGroup({
    category: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required)
  })

  ngOnInit(): void {
    this.authToken = sessionStorage.getItem('auth-user');
    this.token = jwt_decode(this.authToken);

    if (this.token.role === '[ROLE_END_USER]')
      this.gateUserTickets(this.token.accountId);

    if (this.token.role === '[ROLE_AGENT]')
      this.gateAgentTickets(this.token.accountId);

    this.service.RefreshRequired.subscribe(res => { this.gateUserTickets(this.token.accountId) })

    this.connect()
  }

  public get f() { return this.openTicketForm.controls; }

  public openTicket() {
    this.spinner.show();
    this.requestService = Object.assign(this.requestService, this.openTicketForm.value);
    this.requestService.customerUserId = this.token.accountId;

    this.service.requestService(this.token.companyId, this.requestService).subscribe(
      res => {
        if (res) { this.spinner.hide() }
        this.message = res;
        Successful.successful(this.message);
        this.openTicketForm.reset();
      }, error => {
        if (error) { this.spinner.hide() }
        Error.error(error);
      }
    )
  }

  public openchat(ticketId: any): void {
    this.ticketId = ticketId
    this.openChat = true
  }

  private gateUserTickets(userId: any): void {
    this.spinner.show()
    this.service.getUserTickets(userId).subscribe(res => {
      if (res) { this.spinner.hide() }
      this.tickets = res;
      this.filterdTickets = this.tickets;
    }, error => {
      if (error) { this.spinner.hide() }
      Error.error(error);
    })
  }

  private gateAgentTickets(userId: any): void {
    this.spinner.show()
    this.service.getAgentTickets(userId).subscribe(res => {
      if (res) { this.spinner.hide() }
      this.tickets = res;
      this.filterdTickets = this.tickets.filter(ticket => ticket.status === "Open")
    }, error => {
      console.log(error.error.error)
      if (error) { this.spinner.hide() }
      Error.error(error);
    })
  }

  public onStatusChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const status = target.value;
    const prioritySelect = document.getElementById('prioritySelect') as HTMLSelectElement;
    const priority = prioritySelect.value;

    if (status == "all" && priority == "all") {
      this.filterdTickets = this.tickets;
    } else if (status == "all") {
      this.filterdTickets = this.tickets.filter(ticket => ticket.priority === priority);
    } else if (priority == "all") {
      this.filterdTickets = this.tickets.filter(ticket => ticket.status === status);
    } else {
      this.filterdTickets = this.tickets.filter(ticket => ticket.status === status && ticket.priority === priority);
    }
  }

  public onPriorityChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const priority = target.value;
    const statusSelect = document.getElementById('statusSelect') as HTMLSelectElement;
    const status = statusSelect.value;

    if (status == "all" && priority == "all") {
      this.filterdTickets = this.tickets;
    } else if (status == "all") {
      this.filterdTickets = this.tickets.filter(ticket => ticket.priority === priority);
    } else if (priority == "all") {
      this.filterdTickets = this.tickets.filter(ticket => ticket.status === status);
    } else {
      this.filterdTickets = this.tickets.filter(ticket => ticket.status === status && ticket.priority === priority);
    }
  }

  private connect(): void {
    this.stompClient.webSocketFactory = (): IStompSocket => {
      return <IStompSocket>(<unknown>new SockJS('/ticket'));
    };
    this.stompClient.onConnect = (frame) => {
      console.log("Connected to: " + frame);
      this.stompClient.subscribe("/topic/ticket-notification/" + this.token.accountId, (message: Message) => {
        this.gateAgentTickets(this.token.accountId);
        const payload = JSON.parse(message.body);
        console.log('Received message:', payload);

        this.audio = new Audio("../../../assets/mixkit-software-interface-start-2574.wav")
        this.audio.play();

        Swal.fire({
          title: 'Ticket logged',
          text: payload.description,
          icon: 'info',
          confirmButtonColor: '#3818D9',
          confirmButtonText: 'Go to ticket'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl('/ticket')
          }
        })
      })
    }
    this.stompClient.activate();
  }
}