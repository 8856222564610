import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Constants, httpOptionsWithAuth } from "../constants/constants";
import { UpdatePassword } from "../models/models";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    constructor(private httpClient: HttpClient) { }

    public changePassword(updatePassword: UpdatePassword): Observable<any> {
        return this.httpClient.put(Constants.GATEWAY_URL + "users/change-password", updatePassword, httpOptionsWithAuth);
    }
}
