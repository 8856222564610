<main id="main" class="main">
    <div class="pagetitle">
        <h1>Tickets</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">tickets</li>
            </ol>
        </nav>
    </div>
    <section class="section">
        <div class="row">
            <div class="col-lg-12">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Tickets &nbsp;<i title="Tickets for all the users"
                                    class="bi bi-info-circle"></i></h5>
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Ticket ID</th>
                                        <th scope="col">Created At</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Priority</th>
                                        <th scope="col">Logged By</th>
                                        <th scope="col">Assigned To</th>
                                        <th class="text-center" scope="col">Reassign</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ticket of tickets">
                                        <td>#{{ticket.ticketId.substring(0,4)}}</td>
                                        <td>{{ticket.createdAt | date:'dd MMM YYYY'}}</td>
                                        <td>{{ticket.category}}</td>
                                        <td><span
                                                [ngClass]="getStatusColor(ticket.status.toString())">{{ticket.status}}</span>
                                        </td>
                                        <td><span
                                                [ngClass]="getPriorityColor(ticket.priority.toString())">{{ticket.priority}}</span>
                                        </td>
                                        <td>{{ticket.loggedBy}}</td>
                                        <td>{{ticket.assignedTo}}</td>
                                        <td class="text-center"><a style="cursor: pointer; text-decoration: underline; color: var(--primary-color);"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <i title="Change Agent" class="bi bi-arrow-clockwise"></i>
                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <p class="text-center">Change Agent</p>
                                                <form [formGroup]="reassignForm" (ngSubmit)="reassignAgent(ticket.ticketId)" class="form p-2">
                                                    <div class="form-floating">
                                                        <select type="text" class="form-select mb-2" id="statusSelect"
                                                            formControlName="agent"
                                                            [class.is-invalid]="f.agent.invalid && f.agent.touched">
                                                            <option *ngFor="let agent of agents"
                                                                [value]="agent.accountId">{{agent.fullName}}</option>
                                                        </select>
                                                        <label style="color: #6c757d;" for="fullname">Choose
                                                            Agent</label>
                                                    </div>

                                                    <button [disabled]="reassignForm.invalid" class="mt-2" type="submit"
                                                        style="border: none;"
                                                        class="btn btn-primary h-50 w-100">Change</button>
                                                </form>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>