<aside id="sidebar" class="sidebar">
    <nav>
        <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item"> <a class="nav-link" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active"
                    [routerLink]="['/dashboard']"> <i class="bi bi-grid"></i> <span>Dashboard</span> </a></li>
            <li *ngIf="role.role==='[ROLE_COMPANY]'" class="nav-item"> <a class="nav-link collapsed"
                    routerLinkActive="active" routerLink="/register-admin"> <i class="bi bi-people"></i> <span>Register
                        Admin</span> </a></li>
            <li *ngIf="role.role==='[ROLE_ADMIN]'" class="nav-item"> <a class="nav-link collapsed"
                    routerLinkActive="active" routerLink="/reports"> <i class="bi bi-graph-up"></i> <span>Reports</span>
                </a></li>
                <li *ngIf="role.role==='[ROLE_ADMIN]'" class="nav-item"> <a class="nav-link collapsed"
                    routerLinkActive="active" routerLink="/admin-tickets"> <i class="bi bi-ticket-perforated"></i> <span>Tickets</span>
                </a></li>
            <li *ngIf="role.role==='[ROLE_ADMIN]'" class="nav-item"> <a class="nav-link collapsed"
                    routerLinkActive="active" routerLink="/register-user"> <i class="bi bi-people"></i> <span>
                        Users</span> </a></li>
            <li *ngIf="role.role==='[ROLE_AGENT]' || role.role==='[ROLE_END_USER]'" class="nav-item"> <a class="nav-link collapsed"
                    routerLinkActive="active" routerLink="/tickets"> <i class="bi bi-ticket-perforated"></i> <span>
                        Tickets</span> </a></li>

            <li *ngIf="role.role==='[ROLE_COMPANY]'" class="nav-item"> <a class="nav-link collapsed"
                    routerLinkActive="active" routerLink="/subscription"> <i
                        class="bi bi-cash-coin"></i><span>Subscriptions</span> </a></li>
            <li class="nav-item"> <a class="nav-link collapsed" routerLinkActive="active" routerLink="/settings"> <i
                        class="bi bi-gear"></i> <span>Settings</span> </a></li>
            <li class="nav-item"> <a style="cursor: pointer;" class="nav-link collapsed" (click)="logout()"> <i
                        class="bi bi-box-arrow-left"></i> <span>Logout</span> </a></li>
        </ul>
    </nav>
</aside>
<script>
    $('.sidebar-nav ').on('click', function (e) {
        e.preventDefault();
        $('.sidebar-nav ').removeClass('active');
        $(this).addClass('active');
    }); 
</script>