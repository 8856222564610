import Swal from "sweetalert2";

export class Successful{
    static successful(message:any){
        Swal.fire({
            timer:3000,
            title:'Successful',
            text:message.message,
            showConfirmButton:false,
            icon:'success',
            position:'center'
          })
    }
}