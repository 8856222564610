import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap, throwError } from 'rxjs';
import { RegisterUser } from '../models/models';
import { Constants, httpOptionsWithAuth } from '../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(private httpClient: HttpClient) { }

    private refreshrequired = new Subject<void>();
    get RefreshRequired() { return this.refreshrequired; }

    public registerusers(users: RegisterUser[], companyId: any): Observable<any> {
        return this.httpClient.post(Constants.GATEWAY_URL + "users/register-users/" + companyId, users, httpOptionsWithAuth)
            .pipe(tap(() => this.refreshrequired.next()))
    }

    public getusers(companyId: any): Observable<any> {
        return this.httpClient.get(Constants.GATEWAY_URL + "users/get-users/" + companyId, httpOptionsWithAuth);
    }

    public getusersdata(companyId: any): Observable<any> {
        return this.httpClient.get(Constants.GATEWAY_URL + "users/get-users-data/" + companyId, httpOptionsWithAuth);

    }

    public resetpassword(accountId: any, role: any): Observable<any> {
        const url = `${Constants.GATEWAY_URL}users/reset-password/${accountId}/${role}`
        return this.httpClient.put(url, httpOptionsWithAuth);
    }
}